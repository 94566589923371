import { Component, OnInit } from '@angular/core';
import {AppStorageService} from "../../shared/services/general/appstorage.service";
import {utilisateur} from "../../shared/model/utilisateur";
import {ConstantsService} from "../../shared/services/general/constants.service";

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    isBoss: boolean;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Accueil',  icon: 'design_app', class: '', isBoss: false },

    //menus
    { path: '/operations-list', title: 'Operations',  icon:'business_money-coins', class: '', isBoss: false },
    { path: '/reports-menu', title: 'Rapports',  icon:'education_paper', class: '', isBoss: true },

    { path: '/customers-list', title: 'Clients',  icon:'users_single-02', class: '', isBoss: true },
    { path: '/agents-list', title: 'Utilisateurs',  icon:'users_circle-08', class: '', isBoss: true },

    { path: '/settings', title: 'Paramètres',  icon:'ui-1_settings-gear-63', class: '', isBoss: true },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  user: utilisateur;

  constructor(private storageService: AppStorageService) {
      this.user = new utilisateur();
  }

  ngOnInit() {
    // this.user = this.utils.reCreateAgent(this.storageService.getConnectedUser());
    //
    // if(this.user.category === ConstantsService.EMPLOYER){
    //     this.menuItems = ROUTES.filter(menuItem => menuItem );
    // }else{
    //     this.menuItems = ROUTES.filter(menuItem => menuItem && menuItem.isBoss === false);
    // }
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };
}
