import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SecuService} from "../../../shared/services/secu/secu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {LibraryService} from "../../../shared/services/app/library.service";
import {ParamService} from "../../../shared/services/param/param.service";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../shared/services/general/utils.service";
import {ConfirmationService} from "primeng/api";
import {utilisateur} from "../../../shared/model/utilisateur";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {LocalStorageService} from "../../../shared/services/app/localstorage.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  newUser : utilisateur = new utilisateur();

  constructor(private router: Router, private paramService: ParamService, private libraryService: LibraryService,
              private spinner: NgxSpinnerService, private authService: ParamService,
              private toastr: ToastrService , private utils: UtilsService,
              private confirmationService: ConfirmationService, private localStorage: LocalStorageService ) { }

  ngOnInit() {
    this.newUser.adresse_mail = "";
    this.newUser.mot_passe = "";
  }


  //change password
  changePassword(): void {
    //this.router.navigate(["/reset"]);
  }//end changePassword


  //login user
  registerUser(): void {
    if(!this.newUser.canRegister()){
      this.toastr.error(this.newUser.getErrorMessage(), "Inscription MdSC", {positionClass: 'toast-top-center'});
      return;
    }

    //this.toastr.success("Votre compte a été créé avec succès. Veuillez consulter vos mails pour l'activer", "Connexion", {positionClass: 'toast-top-center'});

    this.spinner.show();
    this.authService.createAccount(this.newUser).subscribe((result) => {

      if(result === undefined){
        this.spinner.hide();
        let error: string = "Une erreur est survenue";
        this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
        return;
      }

      if(result.status === "error"){
        this.spinner.hide();
        let error: string = result.message;
        this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
        return;
      }else{
        this.spinner.hide();
        let info: string = result.message;
        this.toastr.success(info, "Inscription MdSC", {positionClass: 'toast-top-center'});


        this.localStorage.saveUser(result.data);
        this.router.navigate(["/soumettre_competence"]);

        return;

      }

      //save user

      //this.newUser.mot_passe = ""; this.newUser.login = "";

      //setTimeout(() => { this.spinner.hide(); }, 5000);

   })

  }//end loginUser


  //back
  back(): void {
    this.router.navigate(["/accueil_competence"]);
  }//end back


  //connect
  connect(): void {
    this.router.navigate(["/login"]);
  }//end connect



}
