import { Pipe, PipeTransform } from '@angular/core';
import {LibraryService} from "../../services/app/library.service";
import {ConstantsService} from "../../services/general/constants.service";

@Pipe({
  name: 'formatStatus'
})
export class FormatStatusPipe implements PipeTransform {

  constructor() {}

    transform(value: any, args: any) {

      if(value.est_demande_validation){
        return "En Attente de validation";
      }

      if(value.est_validee){
        return "Validée";
      }

      if(value.est_annulee){
        return "Annulée";
      }
    }

}
