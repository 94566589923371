import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLangue'
})
export class FormatLanguePipe implements PipeTransform {

    transform(items: any, args: boolean) {
	   
	     if(items === undefined) return [];

				if(args === true){
					
						return items
					  .filter(item =>
						(item.langue.id_type_langue === 1)
					  );
				
				}else{
							return items
						  .filter(item =>
								(item.langue.id_type_langue === 2)
						  );
				}
				  
				}

}
