import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {LoginComponent} from "./components/auth/login/login.component";
//import {PublicDashboardComponent} from "./components/main/home/public/publicdashboard.component";
import {PublicLayoutComponent} from "./layouts/public-layout/public-layout.component";
import {ChangePasswordComponent} from "./components/auth/change/change.password.component";

import { AdminLoginComponent } from "./components/auth/admin_login/admin_login.component";

import { SoumettreCompetenceComponent } from "./components/public/competences/soumettre/soumettre_competence.component";
import {AccueilCompetenceComponent} from "./components/public/competences/accueil/accueil_competence.component";
import {RechercherCompetenceComponent} from "./components/public/competences/rechercher/rechercher_competence.component";
import {RegisterComponent} from "./components/auth/register/register.component";
import {StatsGenComponent} from "./components/public/competences/stats_gen/stats_gen.component";
import {DashBoardCompetencesComponent} from "./components/public/competences/dashboard/dashboard_competences.component";
import {ReglesConfidentialiteComponent} from "./components/public/competences/regles_confidentialite/regles_confidentialite.component";
import {ConditionsUtilisationComponent} from "./components/public/competences/conditions_utilisation/conditions_utilisation.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil_competence',
    pathMatch: 'full',
  },

  //for admin
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }]},

  //for public
  {
    path: 'sidoffepublic',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/public-layout/public-layout.module#PublicLayoutModule'
      }]},


  //accueil_competence
  { path: 'accueil_competence', component: AccueilCompetenceComponent },
  
  //soumettre_competence
  { path: 'soumettre_competence', component: SoumettreCompetenceComponent },
  
  //rechercher_competence
  { path: 'rechercher_competence', component: RechercherCompetenceComponent },
  
  
  { path: 'stats_gen', component: StatsGenComponent },
  
  { path: 'dashboard_competences', component: DashBoardCompetencesComponent },

  //register
  { path: 'register', component: RegisterComponent },

   //login
  { path: 'login', component: LoginComponent }, 
  { path: 'adminlogin', component: AdminLoginComponent },
  
  //rules
  { path: 'conditions_utilisation', component: ConditionsUtilisationComponent },
  { path: 'regles_confidentialite', component: ReglesConfidentialiteComponent },


  { path: 'change', component: ChangePasswordComponent },
  {
    path: '**',
    redirectTo: 'accueil_competence'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
