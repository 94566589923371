
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {AppService} from "../app/app.service";



@Injectable()
export class ReportService {

  constructor(private http: HttpClient, private appService: AppService) {
  }

  //get pssn stats list
  getPssnStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pssn`,
        param, this.appService.httpOptions)
        .pipe(
          catchError(this.appService.handleError<any>(``)
          )
        )
  }//fin getPssnStats

  // print pssn report
  printPssnReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printglobalpssn`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),
        //catchError(this.appService.handleError<any>(``)
        //)
      )
  }//fin printPssnReport

  //get pe stats list
  getPeStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pe`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getPeStats

  // print pe report
  printPeReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printglobalpe`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),
        //catchError(this.appService.handleError<any>(``)
        //)
      )
  }//fin printPeReport


  //get pssn stats list
  getPffgStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pffg`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getPffgStats

  // print pffg report
  printPffgReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printglobalpffg`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),
        //catchError(this.appService.handleError<any>(``)
        //)
      )
  }//fin printPffgReport





  /////////////////////////////////////// area /////////////////////////////////


  // preview pssn file
  previewPssnFiche(item: any) : Observable<any> {
    this.appService.pdfOptions.responseType = 'blob';
    return this.http.post(`${this.appService.getBaseUrl()}reports/printpssnfiche`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),  //catchError(this.appService.handleError<any>(``)  //)
      )
  }//fin previewPssnFiche

  // preview pe file
  previewPeFiche(item: any) : Observable<any> {
    this.appService.pdfOptions.responseType = 'blob';
    return this.http.post(`${this.appService.getBaseUrl()}reports/printpefiche`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),  //catchError(this.appService.handleError<any>(``)  //)
      )
  }//fin previewPeFiche

  // preview pffg file
  previewPffgFiche(item: any) : Observable<any> {
    this.appService.pdfOptions.responseType = 'blob';
    return this.http.post(`${this.appService.getBaseUrl()}reports/printpffgfiche`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),  //catchError(this.appService.handleError<any>(``)  //)
      )
  }//fin previewPffgFiche



}

