import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SecuService} from "../../../shared/services/secu/secu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {LibraryService} from "../../../shared/services/app/library.service";
import {ParamService} from "../../../shared/services/param/param.service";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../shared/services/general/utils.service";
import {ConfirmationService} from "primeng/api";
import {utilisateur} from "../../../shared/model/utilisateur";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {LocalStorageService} from "../../../shared/services/app/localstorage.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  newUser : utilisateur = new utilisateur();
  isPasswordShown: boolean = false;

  constructor(private router: Router, private paramService: ParamService, private libraryService: LibraryService,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService , private utils: UtilsService,
              private confirmationService: ConfirmationService, private localStorage: LocalStorageService ) { }

  ngOnInit() {
    this.newUser.adresse_mail = "";
    this.newUser.mot_passe = "";
  }


  //change password
  changePassword(): void {
    this.router.navigate(["/change"]);
  }//end changePassword
  
  //toogle button 
  toogleState(){
	  this.isPasswordShown = !this.isPasswordShown;
  }//end toogleState
  
	  //set classes
	  getType() {
		  if(this.isPasswordShown) {
			  return "text";
		  }else {
			  return "password";
		  }
	  }//end setMyClasses


  //login user
  loginUser(): void {
              if(!this.newUser.canLogin()){
                this.toastr.error(this.newUser.getErrorMessage(), "Connexion", {positionClass: 'toast-top-center'});
                return;
              }

       this.spinner.show();
              this.authService.login(this.newUser).subscribe((result) => {
             
                if(result === undefined){
                  this.spinner.hide();
                  let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
                  this.toastr.error(error, "Connexion", {positionClass: 'toast-top-center'});
                  return;
                }
             
                if(result.status === "error"){
                  this.spinner.hide();
                  let error: string = result.message;
                  this.toastr.error(error, "Connexion", {positionClass: 'toast-top-center'});
                  return;
                }else{
             
                  //save user
                  this.localStorage.saveUser(result.data);
                  this.newUser.mot_passe = ""; this.newUser.login = "";
             
                  setTimeout(() => { this.spinner.hide(); }, 5000);
             
                  this.router.navigate(["/soumettre_competence"]);
                }
             })
  }//end loginUser


  //back
  back(): void {
    this.router.navigate(["/"]);
  }//end back



}
