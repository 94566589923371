import { Injectable } from '@angular/core';
import {Observable} from "rxjs/index";
import {catchError} from "rxjs/operators";
import {utilisateur} from "../../model/utilisateur";
import {AppService} from "../app/app.service";
import {HttpClient} from "@angular/common/http";
import {localisation} from "../../model/localisation";

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private appService: AppService) { }


  //question
  question(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}questions/create`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin question


  //get categories list
  getCategories() : Observable<any[]> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}categories/all`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getCategories

  //get questions list
  getQuestions() : Observable<any[]> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}questions/all`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getQuestions

  //login
  login(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}form/auth/login`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin login
  
    //loginadmin
  loginadmin(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}form/auth/loginadmin`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin loginadmin


  //reset
  reset(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}form/auth/reset`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin reset

  //reset
  resetOnFirstLogin(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}form/auth/resetafterlogin`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin resetOnFirstLogin


  //get matieres list
  getMatieres() : Observable<any[]> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}matieres/all`)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getMatieres

  //get classes list
  getClasses() : Observable<any[]> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}classes/all`)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getClasses


  //create qcm
  createQcm(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}qcm/create`,
      item, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin createQcm



}
