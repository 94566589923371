import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable()
export class AppStorageService {

  private connectedUserKey: string = "MONENTREPRISE_CONNECTED_USER";
    private selectedAgentKey: string = "MONENTREPRISE_SELECTED_AGENT";
    private selectedProductKey: string = "MONENTREPRISE_SELECTED_PRODUCT";
    private selectedAccountKey: string = "MONENTREPRISE_SELECTED_ACCOUNT";
    private selectedCategoryKey: string = "MONENTREPRISE_SELECTED_CATEGORY";
    private selectedOperationKey: string = "MONENTREPRISE_SELECTED_OPERATION";
    private selectedCustomerKey: string = "MONENTREPRISE_SELECTED_CUSTOMER";
    private selectedCategProductKey: string = "MONENTREPRISE_SELECTED_CATEG_PRODUCT";
    private selectedPlaceKey: string = "MONENTREPRISE_SELECTED_PLACE";
    private selectedMesureTypeKey: string = "MONENTREPRISE_SELECTED_MESURE_TYPE";
    private selectedSupplierKey: string = "MONENTREPRISE_SELECTED_SUPPLIER";
    private selectedProductStockKey: string = "MONENTREPRISE_SELECTED_PRODUCT_STOCK";

  constructor(
      @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  //get connected user
  public setConnectedUser(user: any): void {
      this.storage.set(this.connectedUserKey, user);
  }
  public getConnectedUser(): any {
  let emptyObj = {};
      return this.storage.get(this.connectedUserKey);
  }

    //agent edit
    public setAgent(item: any): void {
        this.storage.set(this.selectedAgentKey, item);
    }
    public getAgent(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedAgentKey);
    }

    //product edit
    public setProduct(item: any): void {
        this.storage.set(this.selectedProductKey, item);
    }
    public getProduct(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedProductKey);
    }

    //product stock edit
    public setProductStock(item: any): void {
        this.storage.set(this.selectedProductStockKey, item);
    }

    public getProductStock(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedProductStockKey);
    }

    //account edit
    public setAccount(item: any): void {
        this.storage.set(this.selectedAccountKey, item);
    }
    public getAccount(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedAccountKey);
    }

    //category operation edit
    public setCategoryOperation(item: any): void {
        this.storage.set(this.selectedCategoryKey, item);
    }
    public getCategoryOperation(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedCategoryKey);
    }

    //operation edit
    public setOperation(item: any): void {
        this.storage.set(this.selectedOperationKey, item);
    }
    public getOperation(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedOperationKey);
    }

    //CUSTOMER edit
    public setCustomer(item: any): void {
        this.storage.set(this.selectedCustomerKey, item);
    }
    public getCustomer(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedCustomerKey);
    }

    //categ product
    public setCategProduct(item: any): void {
        this.storage.set(this.selectedCategProductKey, item);
    }
    public getCategProduct(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedCategProductKey);
    }

    //select stock place
    public setStockPlace(item: any): void {
        this.storage.set(this.selectedPlaceKey, item);
    }

    public getStockPlace(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedPlaceKey);
    }

    //select mesuretype
    public setMesureType(item: any): void {
        this.storage.set(this.selectedMesureTypeKey, item);
    }

    public getMesureType(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedMesureTypeKey);
    }

    //select supplier
    public setSupplier(item: any): void {
        this.storage.set(this.selectedSupplierKey, item);
    }

    public getSupplier(): any {
        let emptyObj = {};
        return this.storage.get(this.selectedSupplierKey);
    }

}
