import { Component, OnInit, Input } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {ReportService} from "../../../../shared/services/report/report.service";
import {structure} from "../../../../shared/model/structure";
import {ParamService} from "../../../../shared/services/param/param.service";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {utilisateur} from "../../../../shared/model/utilisateur";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as FileSaver from 'file-saver';
import {localisation} from "../../../../shared/model/localisation";
import { ActivatedRoute } from '@angular/router';
import {LibraryService} from "../../../../shared/services/app/library.service";
import {mdscform} from "../../../../shared/model/mdscform";
import {parcours_academique} from "../../../../shared/model/parcours_academique";
import {qualification_profesionnelle} from "../../../../shared/model/qualification_professionnelle";
import {ConfirmationService, Message} from 'primeng/primeng';
import {AppService} from "../../../../shared/services/app/app.service";

@Component({
  selector: 'app-detail-competence',
  templateUrl: './detail_competence.component.html',
  styleUrls: ['./detail_competence.component.css']
})
export class DetailCompetenceComponent implements OnInit {

  constructor(private router: Router, private reportService: ReportService, private libraryService: LibraryService,
              private paramService: ParamService, private secuService: SecuService,
              private appService: AppService, private confirmationService: ConfirmationService,
              private localStorage: LocalStorageService, private spinner: NgxSpinnerService,
              private toastr: ToastrService , private utils: UtilsService, private route: ActivatedRoute)
  {
  }

  //form data
  tabIndex: number = 0;
  user: utilisateur;
  formData: mdscform = new mdscform();
  parcoursAcademique: parcours_academique = new parcours_academique();
  qualifPro: qualification_profesionnelle = new qualification_profesionnelle();

  //for uploads
  photoUploadPath: string = "";
  uploadedFiles: any[] = [];
  msgs: Message[] = [];
  resourceBaseUrl: string;
  totalSteps: any = 0;
  @Input() competence: any; 

  //the rest

  public pssnStats: any = {}; paramSearch: any;
   structures: structure[];
  connUser: any;
  codeIndic: string;   private sub: any;

  modal_form: any = {};

  displayCreationQualifAcadForm: boolean = false;
  displayCreationQualifProForm: boolean = false;

  newQualifAcad: any = {};
  selectedDepartement: any = {};

  public langues: any[];
  public niveauxqualification: any[];
  public poolstechniques: any[];
  public expertisesprio: any[];
  public detailsPoolsTechniques: any[] = [];  public filteredThematiques: any[] = [];


  public domainesExpertise: any[] = [ ];
  public niveauxQualif: any[] = [];

  public domainesQualif: any[] = [  ];

  public competencesInformatiques: any[] = [ ];
  public outilsCollecte: any[] = [ ];   public outilsInformatik: any[] = [ ];
  public languesEtrangeres: any[] = [ ];
  public languesLocales: any[] = [];

  public poolExpertises: any[] = [];


  public departements: any[] = [ ];

  communes: any[] = [ ];
  filteredCommunes: any[] = [];

  emptyList: any[] = [];
  listAcademicQualif: any[] = [];
  listProQualif: any[] = [];

  listResourcesPhotos: any[] = [];
  listResourcesCV: any[] = [];
  listResourcesReferences: any[] = [];

  poolsDetailsListe: any[] = [];
  selectedPoolThematique: any = {}; selectedDetailPoolThematique: any = {};

  ngOnInit() {
    this.user = new utilisateur();
    this.photoUploadPath = this.appService.getPhotoUploadUrl();
    this.resourceBaseUrl = this.appService.getResourceUrl();

    // //user connected
    // this.connUser = this.localStorage.getUser();
    // if(this.connUser === null){
    //   this.router.navigate(["/accueil_competence"]);
    // }

    //init data
    //this.paramSearch = { startDate: new Date(), endDate: new Date() };
    // this.filteredCommunes = this.communes;
    // this.formData.profil = this.connUser;
    // this.formData.identite.adresse_mail = this.connUser.adresse_mail;
    // this.formData.identite.telephone_1 = this.connUser.identite.telephone_1;
    // this.formData.identite.telephone_2 = this.connUser.identite.telephone_2;
    // this.formData.identite.site_web = this.connUser.identite.site_web;
    // this.formData.identite.adresse_geo = this.connUser.identite.adresse_geo;
    // this.formData.identite.identifiant_skype = this.connUser.identite.identifiant_skype;
    // this.formData.annees_experience = this.connUser.identite.annees_experience;

    // this.formData.identite.date_naissance = this.libraryService.convertStrDateToJsDate(this.connUser.identite.date_naissance);
    // this.formData.identite.nom = this.connUser.identite.nom ;
    // this.formData.identite.prenoms = this.connUser.identite.prenoms ;

    // //old data for onglet 2 and 3
    // this.formData.oldParcours = this.connUser.identite.parcours_academique_consultants;
    // this.formData.oldQualifications = this.connUser.identite.qualification_pro_consultants;

    // this.getListCommunes();
    // this.getListDepartements();
    // this.getExpertisesPrio();
    // this.getListPoolsTechniques();
    // this.getListLangues();
    // this.getListNiveauxQualification();
    // this.getOutilsInformatiques();
    // this.getListDetailsPoolsTechniques();

    //get form completion: test
    //this.getFormCompletionStatus();

  }//end oninit

  ngOnDestroy() {
    //this.sub.unsubscribe();


  }


  //get form completion status
  getFormCompletionStatus(): void {
    let obj: any = { user: this.connUser.adresse_mail,  };

    this.paramService.getFormCompletionStatusData(obj).subscribe((result) => {

      this.totalSteps = 0;

      //check step 1
      if(this.formData.isStep1Ok(result.data)){ this.totalSteps++;   }

      //check step 2
      if(this.formData.isStep2Ok(result.data)){ this.totalSteps++;   }

      //check step 3
      if(this.formData.isStep3Ok(result.data)){ this.totalSteps++;   }

      //check step 4
      if(this.formData.isStep4Ok(result.data)){ this.totalSteps++;   }

      //check step 5
      if(this.formData.isStep5Ok(result.data)){ this.totalSteps++;   }

      //check step 6
      if(this.formData.isStep6Ok(result.data)){ this.totalSteps++;   }

    })
  }//end getFormCompletionStatus


  //get list departements
  getListDepartements(): void {
    this.paramService.getDepartements().subscribe((result) => {
      this.departements = result;

    })
  }//end getListDepartements

  //get list communes
  getListCommunes(): void {
    this.paramService.getCommunes().subscribe((result) => {
      this.communes = result;

      //get commune
      this.filteredCommunes = this.communes.filter(x => x.id === this.connUser.identite.id_commune_residence);
      if(this.filteredCommunes.length > 0){
        this.formData.identite.commune_residence = this.filteredCommunes[0];
      }

      //this.getListDepartements();
    })
  }//end getListCommunes

  //get list pools techniques
  getListPoolsTechniques(): void {
    this.paramService.getPoolsTechniques().subscribe((result) => {
      this.poolExpertises = result;
      for(let i: number = 0; i < this.poolExpertises.length; i++ ){
        this.poolExpertises[i]["checked"] = false;
      }
    })
  }//end getListPoolsTechniques

  //get list details pools techniques
  getListDetailsPoolsTechniques(): void {
        this.paramService.getDetailsPoolsTechniques().subscribe((result) => {
            this.detailsPoolsTechniques = result;
            // for(let i: number = 0; i < this.poolExpertises.length; i++ ){
            //     this.poolExpertises[i]["checked"] = false;
            // }
        })
    }//end getListDetailsPoolsTechniques

  //get list langues
  getListLangues(): void {
    this.paramService.getLangues().subscribe((result) => {
      this.langues = result;
      this.languesEtrangeres = this.langues.filter(x => x.id_type_langue === 1);
      this.languesLocales = this.langues.filter(x => x.id_type_langue === 2);

      for(let i: number = 0; i < this.languesEtrangeres.length; i++ ){
        this.languesEtrangeres[i]["checked"] = false;
      }

      for(let i: number = 0; i < this.languesLocales.length; i++ ){
        this.languesLocales[i]["checked"] = false;
      }
    })
  }//end getListLangues

  //get list niveaux qualification
  getListNiveauxQualification(): void {
    this.paramService.getNiveauxQualification().subscribe((result) => {
      this.niveauxQualif = result;
    })
  }//end getListNiveauxQualification

  //get list pool techniques
  getPoolsTechniques(): void {
    this.paramService.getPoolsTechniques().subscribe((result) => {
      this.poolstechniques = result;

      for(let i: number = 0; i < this.poolstechniques.length; i++ ){
        this.poolstechniques[i]["checked"] = false;
      }
    })
  }//end getPoolsTechniques

  //get list expertise prio
  getExpertisesPrio(): void {
    this.paramService.getExpertisesPrio().subscribe((result) => {
      this.domainesExpertise = result;

      for(let i: number = 0; i < this.domainesExpertise.length; i++ ){
        this.domainesExpertise[i]["checked"] = false;
      }
    })
  }//end getExpertisesPrio

  //get list outils informatik
  getOutilsInformatiques(): void {
    this.paramService.getOutilsInformatiques().subscribe((result) => {
      this.outilsInformatik = result;
      this.outilsCollecte = this.outilsInformatik.filter(x => x.id_type_outil === 2);
      this.competencesInformatiques = this.outilsInformatik.filter(x => x.id_type_outil === 1);

      for(let i: number = 0; i < this.outilsCollecte.length; i++ ){
        this.outilsCollecte[i]["checked"] = false;
      }

      for(let i: number = 0; i < this.competencesInformatiques.length; i++ ){
        this.competencesInformatiques[i]["checked"] = false;
      }

    })
  }//end getOutilsInformatiques

  //departement changed
  departementChanged() : void {
    this.filteredCommunes = this.communes.filter(x => x.id_departement === this.formData.identite.departement.id);
  }//end departementChanged


  //onTabChange
  onTabChange(event: any): void {
    let tabIndex: number = event.index;
    this.tabChangeEventAction(tabIndex);
  }//end onTabChange

  //tab change events
  tabChangeEventAction(tabIndex: number){
      let stepChange: number = tabIndex+1;
      this.formData.step = "step" + stepChange;

      if(tabIndex === 1){
          //if(this.listAcademicQualif.length === 0)
          this.addAcademicQualification();
      }else{
          if(tabIndex === 2){
              //if(this.listProQualif.length === 0)
              this.addProfessionalQualification();
          }else{
          }
      }
  }//end tabChangeEventAction


    //go next tab
    goNextTab(): void {
        this.tabIndex++;
        if(this.tabIndex === 6) { this.tabIndex = 0; }

        this.tabChangeEventAction(this.tabIndex);
    }//end goNextTab

  //go to last tab
    goLastTab(): void {
        if(this.tabIndex === 0) {
            this.tabIndex = 5;
            return;
            this.tabChangeEventAction(this.tabIndex);
        }
        this.tabIndex--;
        this.tabChangeEventAction(this.tabIndex);
    }//end goLastTab










  //date de debut
  getSelectedDateStart(event: Date){
    if(event !== undefined) event.setHours(new Date().getHours());
    this.paramSearch.startDate = event;
  }

  //date de fin
  getSelectedDateEnd(event: Date){
    if(event !== undefined) event.setHours(new Date().getHours());
    this.paramSearch.endDate = event;
  }

  //init reset form
  initForm(): void {
    this.selectedDepartement = null;  this.paramSearch.commune = null;
    this.paramSearch.structure = null;
    this.paramSearch.startDate = null; this.paramSearch.endDate = null;
  }//end initForm


  //go back
  goBack(): void {
    this.router.navigate(["/accueil_competence"]);
  }//end goBack


  //add academic qualification
  addAcademicQualification(): void {
    this.displayCreationQualifAcadForm = true;

  }//end addAcademicQualification

  //date de debut
  getSelectedDateStartQualifAcad(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    if(this.parcoursAcademique === null || this.parcoursAcademique === undefined) this.parcoursAcademique = new parcours_academique();
    this.parcoursAcademique.debut = event;
  }

  //date de fin
  getSelectedDateEndQualifAcad(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    if(this.parcoursAcademique === null || this.parcoursAcademique === undefined) this.parcoursAcademique = new parcours_academique();
    this.parcoursAcademique.fin = event;
  }
  //close qualif acad
  closeQualifAcadForm(): void {
    this.displayCreationQualifAcadForm = false;
  }//end closeQualifAcadForm


  //saveNewQualifAcad
  saveNewQualifAcad(): void {
    //check first
    if(!this.parcoursAcademique.canSave()){
      this.toastr.error(this.parcoursAcademique.getErrorMessage(), "Attention");
      return;
    }
    this.formData.parcours.push(this.parcoursAcademique);
    this.parcoursAcademique = new parcours_academique();
    this.displayCreationQualifAcadForm = false;

    //this.toastr.show("Un nouveau parcours académique a été ajouté", "MDSC");
  }//end saveNewQualifAcad




  //pro qualif area
  //add professional qualification
  addProfessionalQualification(): void {
    this.displayCreationQualifProForm = true;
  }//end addProfessionalQualification

  //close qualif pro
  closeQualifProForm(): void {
    this.displayCreationQualifProForm = false;
  }//end closeQualifProForm

  //saveNewQualifPro
  saveNewQualifPro(): void {

    //check first
    if(!this.qualifPro.canSave()){
      this.toastr.error(this.qualifPro.getErrorMessage(), "Attention");
      return;
    }
    this.formData.qualifications.push(this.qualifPro);
    this.qualifPro = new qualification_profesionnelle();
    this.displayCreationQualifProForm = false;

    //this.toastr.show("Une nouvelle qualification professionnelle a été ajoutée", "MDSC");
  }//end saveNewQualifPro


  //date de debut
  getSelectedDateStartQualifPro(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    if(this.qualifPro === null || this.qualifPro === undefined) this.qualifPro = new qualification_profesionnelle();
    this.qualifPro.debut = event;
  }//end getSelectedDateStartQualifPro

  //date de fin
  getSelectedDateEndQualifPro(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    if(this.qualifPro === null || this.qualifPro === undefined) this.qualifPro = new qualification_profesionnelle();
    this.qualifPro.fin = event;
  }//end getSelectedDateEndQualifPro

  //date de naissance
  getSelectedDateNaissance(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    this.formData.identite.date_naissance = event;
  }//end getSelectedDateNaissance


  //pool technique
  clickEvtPoolTechnique(event: any){
    if(this.formData.poolsTechniques.length === 3){
      if(event.target.checked === true){
        alert("Vous ne pouvez ajouter plus de 3 pools techniques!");
        event.preventDefault();
        return;
      }
    }
  }//end clickEvtPoolTechnique


  //pool technique area
  setPoolTechnique(item: any, eltIndex: any): void {
    let indexItem: number = this.formData.poolsTechniques.findIndex(x => x.id === item.id);

    if(item.checked){

      //checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.poolsTechniques.push(item);
      }

    }else{
      //not checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.poolsTechniques.splice(indexItem, 1);
      }
    }
  }//end setPoolTechnique



  //domaine expertise prioritaire
  clickEvtDomaineExpertisePrio(event: any){
    if(this.formData.domainesExpPrio.length === 3){
      if(event.target.checked === true){
        alert("Vous ne pouvez ajouter plus de 3 domaines d'expertise prioritaire!");
        event.preventDefault();
        return;
      }
    }
  }//end clickEvtDomaineExpertisePrio

  //domaine expertis eprio area
  setDomaineExpertisePrio(item: any): void {
    let indexItem: number = this.formData.domainesExpPrio.findIndex(x => x.id === item.id);

    if(item.checked){
      //checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.domainesExpPrio.push(item);
      }
    }else{
      //not checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.domainesExpPrio.splice(indexItem, 1);
      }
    }
  }//end setDomaineExpertisePrio

  //langue etrangere parlee area
  setLangueEtrangereParlee(item: any): void {
    let indexItem: number = this.formData.languesEtrangeres.findIndex(x => x.id === item.id);

    if(item.checked){
      //checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.languesEtrangeres.push(item);
      }
    }else{
      //not checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.languesEtrangeres.splice(indexItem, 1);
      }
    }
  }//end setLangueEtrangereParlee

  //langue locale parlee area
  setLangueLocaleParlee(item: any): void {
    let indexItem: number = this.formData.languesLocales.findIndex(x => x.id === item.id);

    if(item.checked){
      //checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.languesLocales.push(item);
      }
    }else{
      //not checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.languesLocales.splice(indexItem, 1);
      }
    }
  }//end setLangueLocaleParlee


  //setCompetenceInformatique area
  setCompetenceInformatique(item: any): void {
    let indexItem: number = this.formData.competencesInformatik.findIndex(x => x.id === item.id);

    if(item.checked){
      //checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.competencesInformatik.push(item);
      }
    }else{
      //not checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.competencesInformatik.splice(indexItem, 1);
      }
    }
  }//end setCompetenceInformatique


  //setOutilCollecte area
  setOutilCollecte(item: any): void {
    let indexItem: number = this.formData.outilsCollectes.findIndex(x => x.id === item.id);

    if(item.checked){
      //checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.outilsCollectes.push(item);
      }
    }else{
      //not checked
      if( (indexItem !== undefined) && (indexItem !== null)){
        this.formData.outilsCollectes.splice(indexItem, 1);
      }
    }
  }//end setOutilCollecte






  //save expertise

  //save expertise
  saveExpertise(){

    //check first
    if(!this.formData.canSave()){
      this.toastr.error(this.formData.getErrorMessage(), "Attention");
      return;
    }

    //prepare the object
    this.formData.prepareObjectForSave();

    //save now
    this.spinner.show();
    this.paramService.createExpertise(this.formData).subscribe((result) => {

      if(result === undefined){
        this.spinner.hide();
        let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
        this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
        return;
      }

      if(result.status === "error"){
        this.spinner.hide();
        let error: string = result.message;
        this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
        return;
      }else{

        //get completion status
        this.getFormCompletionStatus();

        this.spinner.hide();
        let info: string = result.message;
        this.toastr.success(info, "Inscription MdSC", {positionClass: 'toast-top-center'});
        return;
      }
    })
  }//end saveExpertise


  //disconnect
  disconnect(): void {
    this.localStorage.saveUser(null);

    this.router.navigate(["/accueil_competence"]);
  }//end disconnect





  //before upload
  public onBeforeUploadHandler(event: any, type: any)
  {
    // called before the file(s) are uploaded
    // Send the currently selected folder in the Header
    event.formData.append("user", this.connUser.adresse_mail);
    event.formData.append("type", type);
  }

  //on file upload
  onUpload($event: any, type: any){

    let info: string = "Votre " + type + " a été chargé(e) avec succès";
    this.toastr.info(info, "Ressource MDSC", {positionClass: 'toast-top-center'});

    //get data
    let obj: any = { user: this.connUser.adresse_mail, type: type };
    this.getFileRelatedData(obj, type);

  }//fin onUpload


  //get file related data
  getFileRelatedData(item: any, type: any): void {
    this.paramService.getResourceRelatedData(item).subscribe((result) => {
      if(type ==="PHOTO"){
        this.listResourcesPhotos = result.data;
      }else{
        if(type ==="CV"){
          this.listResourcesCV = result.data;
        }else{
          this.listResourcesReferences = result.data;
        }
      }

      console.log(result.data);

    })
  }//end getFileRelatedData



  //get user identity
  getUserIdentity(): string {
      let identity: string = this.connUser.identite.nom + " " + this.connUser.identite.prenoms + " (" + this.connUser.adresse_mail + ") ";
      return identity;
  }//end getUserIdentity



  //remove live parcours
  removeLiveParcours(item: any, index: number): void {
    this.formData.parcours.splice(index, 1);
  }//end removeLiveParcours

  //remove live qualification
  removeLiveQualification(item: any, index: number): void {
    this.formData.qualifications.splice(index, 1);
  }//end removeLiveQualification

  //remove saved parcours
  removeSavedParcours(item: any, index: number): void {
    //ask for confirmation first
    this.confirmationService.confirm({
      message: 'Voulez-vous supprimer ce parcours professionnel ' + item.domaine_etude + ' ?',
      accept: () => {

        //action
        this.spinner.show();
        this.paramService.deleteParcoursAcademique(item).subscribe((result) => {

          if(result.status === "error"){
            this.spinner.hide();
            let error: string = result.message;
            this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
            return;
          }else{
            this.spinner.hide();
            this.connUser.identite.parcours_academique_consultants = result.data;

            let info: string = result.message;
            this.toastr.success(info, "Inscription MdSC", {positionClass: 'toast-top-center'});
            return;
          }
        })
      }
    });
  }//end removeSavedParcours


  //remove saved qualification
  removeSavedQualification(item: any, index: number): void {
    //ask for confirmation first
    this.confirmationService.confirm({
      message: 'Voulez-vous supprimer cette qualification professionnelle ' + item.nom_poste + ' ?',
      accept: () => {

        //action
        this.spinner.show();
        this.paramService.deleteQualificationPro(item).subscribe((result) => {

          if(result.status === "error"){
            this.spinner.hide();
            let error: string = result.message;
            this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
            return;
          }else{
            this.spinner.hide();
            this.connUser.identite.qualification_pro_consultants = result.data;

            let info: string = result.message;
            this.toastr.success(info, "Inscription MdSC", {positionClass: 'toast-top-center'});
            return;
          }
        })
      }
    });
  }//end removeSavedQualification


  //add detail pool thematique
  addDetailPoolThematique(): void {

        //check data

        if(this.selectedPoolThematique === null || this.selectedPoolThematique === undefined){
            return;
        }
      if(this.selectedDetailPoolThematique === null || this.selectedDetailPoolThematique === undefined){
          return;
      }

        //check aspects
        //same detail
        let fromSameDetailList: any[] = this.formData.detailsPoolsThematiques.filter(
            x => x.id === this.selectedDetailPoolThematique.id
        );

        if(fromSameDetailList.length >= 1){
            let error: string = "Vous ne pouvez ajouter plus d'une fois le même pool";
            this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
            return;
        }

        //from same category
        let fromSamePoolList: any[] = this.formData.detailsPoolsThematiques.filter(
            x => x.id_type_pool_technique === this.selectedDetailPoolThematique.id_type_pool_technique
        );
        if(fromSamePoolList.length >= 2){
            let error: string = "Vous ne pouvez ajouter plus de deux thématiques du même pool";
            this.toastr.error(error, "Inscription MdSC", {positionClass: 'toast-top-center'});
          return;
        }

        //if more than two, ignore
        // let fromSamePoolList: any[] = this.formData.detailsPoolsThematiques.s(
        //     x => x.id === this.selectedDetailPoolThematique.id
        // );

        //no more than two pools

    //add
    this.selectedDetailPoolThematique["pool"] = this.selectedPoolThematique;
    this.formData.detailsPoolsThematiques.push(this.selectedDetailPoolThematique);

    //init
    this.selectedPoolThematique = null;
    this.selectedDetailPoolThematique = null;
    this.filteredThematiques = [];

    }//end addDetailPoolThematique

    //remove pool detail
    removePoolDetail(item: any, index: number): void {
        this.formData.detailsPoolsThematiques.splice(index, 1);
    }//end removePoolDetail


  //pool changed
    poolChanged(): void {
        this.filteredThematiques = this.detailsPoolsTechniques.filter(
            x => x.id_type_pool_technique === this.selectedPoolThematique.id
        );
    }//end poolChanged




}
