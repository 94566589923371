import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {ReportService} from "../../../../shared/services/report/report.service";
import {structure} from "../../../../shared/model/structure";
import {ParamService} from "../../../../shared/services/param/param.service";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {utilisateur} from "../../../../shared/model/utilisateur";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as FileSaver from 'file-saver';
import {localisation} from "../../../../shared/model/localisation";
import { ActivatedRoute } from '@angular/router';
import {LibraryService} from "../../../../shared/services/app/library.service";

@Component({
  selector: 'app-rechercher-competence',
  templateUrl: './rechercher_competence.component.html',
  styleUrls: ['./rechercher_competence.component.css']
})
export class RechercherCompetenceComponent implements OnInit {

  constructor(private router: Router, private reportService: ReportService, private libraryService: LibraryService,
              private paramService: ParamService, private secuService: SecuService,
              private localStorage: LocalStorageService, private spinner: NgxSpinnerService,
              private toastr: ToastrService , private utils: UtilsService, private route: ActivatedRoute)
  {
  }

  public pssnStats: any = {};
  departements: localisation[];   paramSearch: any;
  communes: localisation[]; structures: structure[];
  selectedDepartement: localisation;  selectedCommune: localisation;
  connUser: utilisateur;
  codeIndic: string;   private sub: any;

  experts: any[] = [];


  pageStartIndex : number = 0; limitTable: number[] = []; pageLimit: number = 0;

  trackByFn(): void {}



  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.codeIndic = params['code'];
    });
    this.connUser = this.localStorage.getUser();
    this.paramSearch = { startDate: new Date(), endDate: new Date() };
    this.limitTable = this.libraryService.getPaginatorLimitList(); this.pageLimit = this.libraryService.getPaginatorDefaultLimit();

    //get experts
    this.getListExpertises();

  }//end oninit

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  //get list departements
  getListExpertises(): void {
    this.paramService.getListExperts().subscribe((result) => {
      if(result.status === "error"){
        alert((result.message)); return;
      }
      this.experts = result;
    })
  }//end getListExpertises



  //date de debut
  getSelectedDateStart(event: Date){
    if(event !== undefined) event.setHours(new Date().getHours());
    this.paramSearch.startDate = event;
  }

  //date de fin
  getSelectedDateEnd(event: Date){
    if(event !== undefined) event.setHours(new Date().getHours());
    this.paramSearch.endDate = event;
  }

  //init reset form
  initForm(): void {
    this.selectedDepartement = null;  this.paramSearch.commune = null;
    this.paramSearch.structure = null;
    this.paramSearch.startDate = null; this.paramSearch.endDate = null;
  }//end initForm

  //has no references
  hasNoReferences(item: any){
    if(item.ressources_consultants === undefined) return true;
    if(item.ressources_consultants.length === 0) return true; else return false;
  }//end hasNoReferences


  //paginate on page change
  paginate(event: any): void{
    this.pageStartIndex = event.first;
    this.pageLimit = event.rows;
  }//fin

}
