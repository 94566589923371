
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {AppService} from "../app/app.service";
import {domaine} from "../../model/domaine";
import {thematique} from "../../model/thematique";
import {type_donnee_collectee} from "../../model/type_donnee_collectee";
import {donnee_collectee} from "../../model/donnee_collectee";
import {type_structure} from "../../model/type_structure";
import {structure} from "../../model/structure";
import {utilisateur} from "../../model/utilisateur";
import {localisation} from "../../model/localisation";


@Injectable()
export class ParamService {

  constructor(private http: HttpClient, private appService: AppService) {
  }

  //get departements list
  getDepartements() : Observable<any[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}form/departements`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
  )
  }//fin getDepartements


  //get communes list
  getCommunes() : Observable<any[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}form/communes`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getCommunes

  // search langues list
  getLangues() : Observable<any> {

    return this.http.get(`${this.appService.getBaseUrl()}form/langues`,
      )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        )
      )
  }//fin getLangues

  // get niveauxqualification
  getNiveauxQualification() : Observable<any[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}form/niveauxqualification`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getNiveauxQualification

  // search poolstechniques list
  getPoolsTechniques() : Observable<any> {

    return this.http.get(`${this.appService.getBaseUrl()}form/poolstechniques`,
      )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        )
      )
  }//fin getPoolsTechniques


    // search detailspoolstechniques list
    getDetailsPoolsTechniques() : Observable<any> {

        return this.http.get(`${this.appService.getBaseUrl()}form/detailspoolstechniques`,
        )
            .pipe(
                //tap(_ => console.log(``)),
                catchError(this.appService.handleError<localisation>(``)
                )
            )
    }//fin getDetailsPoolsTechniques



  //get expertisesprio list
  getExpertisesPrio() : Observable<any[]> {
    return this.http.get<domaine[]>(`${this.appService.getBaseUrl()}form/expertisesprio`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<domaine[]>(``))
      )
  }//fin getDomaines


  // search outils informatik list
  getOutilsInformatiques() : Observable<any> {

    return this.http.get(`${this.appService.getBaseUrl()}form/outilsinformatiques`,
    )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        )
      )
  }//fin getOutilsInformatiques







  //get expertise list
  getListExperts() : Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}list/experts`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getListExperts






  //get file related data
  getResourceRelatedData(item: any) : Observable<any> {
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}resource/file/data`, item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getResourceRelatedData



  //get general stats data
  getGeneralStatsData() : Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}stats/general`,)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getGeneralStatsData



    //get expert details data
    getExpertDetailData(item: any) : Observable<any> {
      return this.http.get<any[]>(`${this.appService.getBaseUrl()}details/expert/${item.id_identite}`,)
        .pipe(
          //tap(_ => console.log(``)),
          catchError(this.appService.handleError<any[]>(``))
        )
    }//fin getExpertDetailData

        //supprimer competence
        removeExpertise(item: any) : Observable<any> {
          return this.http.get<any[]>(`${this.appService.getBaseUrl()}details/expert/remove/${item.id}`,)
            .pipe(
              //tap(_ => console.log(``)),
              catchError(this.appService.handleError<any[]>(``))
            )
        }//fin removeExpertise



  ///////////////////////////////////////options area /////////////////////////////////



	  //get admin list
	  getAdminUsers() : Observable<any[]> {
		return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}admin/list/adminusers`)
		  .pipe(
			//tap(_ => console.log(``)),
			catchError(this.appService.handleError<localisation[]>(``))
		  )
	  }//fin getAdminUsers



//////post requests//////


  //get dashboard data list
  getExpertsDashBoardData(item: any) : Observable<any> {
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}stats/dashboard`, item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getExpertsDashBoardData
  
    //create account
  createAccount(item: any) : Observable<any> {
    return this.http.post<donnee_collectee[]>(`${this.appService.getBaseUrl()}form/createaccount`, item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<donnee_collectee[]>(``))
      )
  }//fin createAccount



  //create expertise
  createExpertise(item: any) : Observable<any> {
    return this.http.post<donnee_collectee[]>(`${this.appService.getBaseUrl()}form/createxpertise`, item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<donnee_collectee[]>(``))
      )
  }//fin createExpertise
  
    // delete parcours academique item
  deleteParcoursAcademique(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}form/parcoursacademique/remove`, item, this.appService.httpOptions
    )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``),
        )
      )
  }//fin deleteParcoursAcademique


  // delete qualification professionnelle
  deleteQualificationPro(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}form/qualificationpro/remove`, item, this.appService.httpOptions
    )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``),
        )
      )
  }//fin deleteQualificationPro
  
  
    //get form completion status data
  getFormCompletionStatusData(item: any) : Observable<any> {
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}form/completion/status`, item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getFormCompletionStatusData
  
  
      //get user data for edit
  getUserDataForEdit(email: any) : Observable<any> {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}users/edit/${email}`)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getUserDataForEdit
  
  
  //remove expert data
  removeExpertData(item: any) : Observable<any> {
    return this.http.post<any[]>(`${this.appService.getBaseUrl()}users/remove`, item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin removeExpertData


}

