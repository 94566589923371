import {donnee_collectee} from "./donnee_collectee";


export class FS_PF_FG_Detail {

  public id: number;
  public fs_pf_fg_id: number;
  public donnees_collectees_id: number;
  public donnee_collectee: donnee_collectee;

  public Sexe_H: number;
  public Sexe_F: number;

  public TA_0_17: number;
  public TA_18_59: number;
  public TA_SUP_60: number;

  public TA_0_4: number;
  public TA_5_9: number;
  public TA_10_14: number;
  public TA_15_17: number;

  public Total: number;

  public Handicap: number;

  public ou: string;
  public qui: string;
  public quand: string;

  //validation fields
  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check if is not empty
  isNotEmpty(): boolean {
    let statusNotEmpty: boolean = false;

    statusNotEmpty =
      (this.Sexe_H !== undefined && this.Sexe_H !== null) && (this.Sexe_F !== undefined && this.Sexe_F !== null)
      && (
        //condition on group 1 of ages
        ( (this.TA_0_17 !== undefined && this.TA_0_17 !== null) && (this.TA_18_59 !== undefined && this.TA_18_59 !== null)  && (this.TA_SUP_60 !== undefined && this.TA_SUP_60 !== null)  )

          ||

       //conditions of group 2 of ages
       ( (this.TA_0_4 !== undefined && this.TA_0_4 !== null) && (this.TA_5_9 !== undefined && this.TA_5_9 !== null)  && (this.TA_10_14 !== undefined && this.TA_10_14 !== null)   && (this.TA_15_17 !== undefined && this.TA_15_17 !== null)  )
      )

      //handicap
      && (this.Handicap !== undefined && this.Handicap !== null);

    if(!statusNotEmpty) { this.errorMessage = "Veuillez renseigner tous les champs";  }  else {  return statusNotEmpty; }  // + this.donnee_collectee.designation ;

    //return statusNotEmpty;
  }//end isNotEmpty

  //check if handicap is valid
  isHandicapValid(): boolean {
    if(!this.isNotEmpty()) return false;
    let statusHandicapValid: boolean = false;

    statusHandicapValid =
      (this.Handicap !== undefined && this.Handicap !== null) &&
      (this.Handicap <= (this.Sexe_F + this.Sexe_H)  );

    if(!statusHandicapValid) { this.errorMessage = "Veuillez renseigner une valeur de handicap valide"; } else {   return statusHandicapValid; }   // pour l'indicateur " + this.donnee_collectee.designation ;

    //return statusHandicapValid;
  }//end isHandicapValid

  //check if total sexe and total ages are valid
  isTotalValid(): boolean {

    //check not emptyness
    if(!this.isNotEmpty()) return false;

    //set default values because of totals
    let lineSexe_H: number = this.Sexe_H; if(this.Sexe_H === undefined || this.Sexe_H === null) lineSexe_H = 0;
    let lineSexe_F: number = this.Sexe_F; if(this.Sexe_F === undefined || this.Sexe_F === null) lineSexe_F = 0;

    let lineTA_0_17: number = this.TA_0_17; if(this.TA_0_17 === undefined || this.TA_0_17 === null) lineTA_0_17 = 0;
    let lineTA_18_59: number = this.TA_18_59; if(this.TA_18_59 === undefined || this.TA_18_59 === null) lineTA_18_59 = 0;
    let lineTA_SUP_60: number = this.TA_SUP_60; if(this.TA_SUP_60 === undefined || this.TA_SUP_60 === null) lineTA_SUP_60 = 0;

    let lineTA_0_4: number = this.TA_0_4; if(this.TA_0_4 === undefined || this.TA_0_4 === null) lineTA_0_4 = 0;
    let lineTA_5_9: number = this.TA_5_9; if(this.TA_5_9 === undefined || this.TA_5_9 === null) lineTA_5_9 = 0;
    let lineTA_10_14: number = this.TA_10_14; if(this.TA_10_14 === undefined || this.TA_10_14 === null) lineTA_10_14 = 0;
    let lineTA_15_17: number = this.TA_15_17; if(this.TA_15_17 === undefined || this.TA_15_17 === null) lineTA_15_17 = 0;


    //check now
    let statusTotalValid: boolean = false;

    statusTotalValid =
      (  (lineSexe_H + lineSexe_F) === (lineTA_0_17 + lineTA_18_59 + lineTA_SUP_60)  )
      || ( (lineSexe_H + lineSexe_F) === (lineTA_0_4 + lineTA_5_9 + lineTA_10_14 + lineTA_15_17)  ) ;

    if(!statusTotalValid)  { this.errorMessage = "Les totaux des niveaux de désagrégation Age et Sexe ne sont pas conformes"; }  else {  return statusTotalValid; }  // + this.donnee_collectee.designation ;

    //return statusTotalValid;
  }//end isTotalValid



  //check if is valid
  isValid(): boolean {
    //check not emptyness
    if(!this.isNotEmpty()) return false;

    let statusValid: boolean = false;

    statusValid =
      (this.Sexe_H >= 0) && (this.Sexe_F >= 0)
      && (
        //condition on group 1 of ages
        ( (this.TA_0_17 >= 0 ) && (this.TA_18_59  >= 0)  && (this.TA_SUP_60  >= 0)  )

        ||

        //conditions of group 2 of ages
        ( (this.TA_0_4  >= 0) && (this.TA_5_9  >= 0)  && (this.TA_10_14  >= 0)   && (this.TA_15_17  >= 0)  )
      )

      //handicap
      && (this.Handicap  >= 0);

    if(!statusValid)  { this.errorMessage = "Veuillez vérifier les valeurs saisies"; } else {  return statusValid; } // + this.donnee_collectee.designation ;


  }//end isValid

  //recreate fiche fspffg details
  public static recreateFicheFsPffgDetails(item: any): FS_PF_FG_Detail {
    let newItem: FS_PF_FG_Detail = new FS_PF_FG_Detail();

    newItem.id = item.id;
    newItem.fs_pf_fg_id = item.fs_pf_fg_id;
    newItem.donnees_collectees_id = item.donnees_collectees_id;
    newItem.donnee_collectee = item.donnee_collectee;
    newItem.Sexe_H = item.Sexe_H;
    newItem.Sexe_F = item.Sexe_F;

    newItem.TA_0_17 = item.TA_0_17;
    newItem.TA_18_59 = item.TA_18_59;
    newItem.TA_SUP_60 = item.TA_SUP_60;

    newItem.TA_0_4 = item.TA_0_4;
    newItem.TA_5_9 = item.TA_5_9;
    newItem.TA_10_14 = item.TA_10_14;
    newItem.TA_15_17 = item.TA_15_17;

    newItem.Total = item.Total;
    newItem.Handicap = item.Handicap;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPffgDetails


}
