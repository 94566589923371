


export class qualification_profesionnelle {

  public id: number;
  public nom_poste: string;
  public institution: string;
  public description_poste: string;
  public debut: any;
  public fin: any;

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {
    if(this.nom_poste === undefined || this.nom_poste === null || this.nom_poste.trim() === ""){
      this.errorMessage = "Veuillez indiquer le nom du poste"; return false;
    }
    if(this.institution === undefined || this.institution === null || this.institution.trim() === ""){
      this.errorMessage = "Veuillez indiquer le nom de l'institution"; return false;
    }
    if(this.description_poste === undefined || this.description_poste === null || this.description_poste.trim() === ""){
      this.errorMessage = "Veuillez indiquer la description du poste"; return false;
    }
    if(this.debut === undefined || this.debut === null){
      this.errorMessage = "Veuillez indiquer la date de début"; return false;
    }
    if(this.fin === undefined || this.fin === null){
      this.errorMessage = "Veuillez indiquer la date de fin"; return false;
    }

      return true;
  }//end canSave


}
