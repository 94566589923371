import {groupe} from "./groupe";
import {structure} from "./structure";
import * as EmailValidator from 'email-validator';


export class utilisateur {

    public id: number;
    public login: string;

    public nom: string;
    public prenoms: string;
    public telephone_1: number;
    public adresse_mail: string;
    public date_naissance: any;
    public departement: any;
    public commune_residence: any;
    public site_web: string;
    public telephone_2: string;
    public adresse_geo: string;
    public identifiant_skype: string;

    public mot_passe: string;
    public mot_passe_confirmation: string;
    public contact: string;
    public photo: string;
    public est_premiere_connexion: boolean;

    public structure_id: number;
    public structure: structure;

    public groupe_id: number;
    public groupe: groupe;

    public ou: string;
    public qui: string;
    public quand: any;

    public autorisations : [];


    //for password change
    public old_mot_passe: string;
    public new_mot_passe: string;
    public new_mot_passe_confirmation: string;

    private errorMessage: string;

    constructor(){
        this.errorMessage = "";
    }

    //get error message
    getErrorMessage(): string {
        return this.errorMessage;
    }//end getErrorMessage


  //check for save
  canSave(): boolean {

    if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
      this.errorMessage = "Veuillez saisir votre nom"; return false;
    }
    if(this.prenoms === undefined || this.prenoms === null || this.prenoms.trim() === ""){
      this.errorMessage = "Veuillez saisir votre prénom"; return false;
    }
    if(this.date_naissance === undefined || this.date_naissance === null){
      this.errorMessage = "Veuillez renseigner votre date de naissance"; return false;
    }

    if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
      this.errorMessage = "Veuillez saisir votre adresse mail"; return false;
    }
    //adresse email valide
    if(!EmailValidator.validate(this.adresse_mail.trim())){
      this.errorMessage = "Votre adresse mail est invalide. Veuillez vous assurer qu'elle ne contient aucun espace"; return false;
    }
    if(this.telephone_1 === undefined || this.telephone_1 === null){
      this.errorMessage = "Veuillez saisir votre numéro de téléphone"; return false;
    }

    // if(this.departement === undefined || this.departement === null){
    //   this.errorMessage = "Veuillez sélectionner votre département"; return false;
    // }
    if(this.commune_residence === undefined || this.commune_residence === null  || this.commune_residence.id === undefined){
      this.errorMessage = "Veuillez indiquer votre commune de résidence"; return false;
    }

    return true;
  }//end canSave


  //check for save
    canSave2(): boolean {

        if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
            this.errorMessage = "Nom de l'utilisateur manquant"; return false;
        }
        if(this.prenoms === undefined || this.prenoms === null || this.prenoms.trim() === ""){
            this.errorMessage = "Prénom de l'utilisateur manquant"; return false;
        }
        if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
            this.errorMessage = "Adresse mail manquante"; return false;
        }
        //adresse email valide
        if(!EmailValidator.validate(this.adresse_mail)){
          this.errorMessage = "Adresse mail invalide"; return false;
        }

        if(this.contact === undefined || this.contact === null || this.contact.trim() === ""){
            this.errorMessage = "Contact téléphonique manquant"; return false;
        }

        if(this.groupe === undefined || this.groupe === null || this.groupe.id === undefined){
          this.errorMessage = "groupe de l'utilisateur manquant"; return false;
        }
        if(this.structure === undefined || this.structure === null || this.structure.id === undefined){
          this.errorMessage = "structure de l'utilisateur manquante"; return false;
        }

        if(this.login === undefined || this.login === null || this.login.trim() === ""){
          this.errorMessage = "Login manquant"; return false;
        }

        //mot de passe
        if(this.mot_passe === undefined || this.mot_passe === null || this.mot_passe.trim() === ""){
          this.errorMessage = "Mot de passe manquant"; return false;
        }
        if(this.mot_passe.length < 5){
          this.errorMessage = "Le Mot de passe doit contenir au moins 5 caractères"; return false;
        }

        //mot de passe de confirmation
        if(this.mot_passe_confirmation === undefined || this.mot_passe_confirmation === null || this.mot_passe_confirmation.trim() === ""){
          this.errorMessage = "Mot de passe de confirmation manquant"; return false;
        }
        if(this.mot_passe !== this.mot_passe_confirmation){
          this.errorMessage = "Le mot de passe et sa confirmation doivent être identiques"; return false;
        }
        if(this.mot_passe_confirmation.length < 5){
          this.errorMessage = "Le Mot de passe de confirmation doit contenir au moins 5 caractères"; return false;
        }

        return true;
    }//end canSave2


  //check for register
  canRegister(): boolean {

    if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
      this.errorMessage = "Veuillez saisir votre nom"; return false;
    }
    if(this.prenoms === undefined || this.prenoms === null || this.prenoms.trim() === ""){
      this.errorMessage = "Veuillez saisir vos prénoms"; return false;
    }

    if(this.telephone_1 === undefined || this.telephone_1 === null){
      this.errorMessage = "Veuillez saisir votre numéro de téléphone"; return false;
    }
    //check on phonne validity
    if(new String(this.telephone_1).length < 8 || new String(this.telephone_1).length > 11){
      this.errorMessage = "Votre numéro de téléphone doit comporter entre 8 et 11 chiffres. Veuillez le corriger puis réessayer"; return false;
    }

    if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
      this.errorMessage = "Veuillez saisir votre adresse email"; return false;
    }

    //adresse email valide
    if(!EmailValidator.validate(this.adresse_mail.trim())){
      this.errorMessage = "Votre adresse mail est invalide. Veuillez vous assurer qu'elle ne contient aucun espace."; return false;
    }

    if(this.mot_passe === undefined || this.mot_passe === null || this.mot_passe.trim() === ""){
      this.errorMessage = "Veuillez saisir un mot de passe"; return false;
    }
    if(this.mot_passe.length <= 4){
      this.errorMessage = "Le mot de passe est trop court. Veuillez le corriger puis réessayer"; return false;
    }

    if(this.mot_passe_confirmation === undefined || this.mot_passe_confirmation === null || this.mot_passe_confirmation.trim() === ""){
      this.errorMessage = "Veuillez confirmer le mot de passe"; return false;
    }

    if(this.mot_passe !== this.mot_passe_confirmation){
      this.errorMessage = "Le mot de passe et le mot de passe de confirmation ne sont pas identiques. Veuillez corriger et réessayer"; return false;
    }

    return true;
  }//end canRegister



    //check for login
    canLogin(): boolean {
      if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
        this.errorMessage = "Veuillez indiquer votre adresse email"; return false;
      }
      if(!EmailValidator.validate(this.adresse_mail)){
        this.errorMessage = "Votre adresse mail est invalide"; return false;
      }
      if(this.mot_passe === undefined || this.mot_passe === null || this.mot_passe.trim() === ""){
        this.errorMessage = "Veuillez saisir votre mot de passe"; return false;
      }
      if(this.mot_passe.length <= 4){
        this.errorMessage = "Votre mot de passe est trop court. Veuillez le corriger puis réessayer"; return false;
      }

        return true;
    }//end canLogin

  //check for reset
  canReset(): boolean {
    if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
      this.errorMessage = "Veuillez saisir votre adresse email"; return false;
    }
    //adresse email valide
    if(!EmailValidator.validate(this.adresse_mail)){
      this.errorMessage = "L'adresse email saisie est invalide. Veuillez la corriger puis réeessayer"; return false;
    }

    return true;
  }//end canReset

  //check for reset on first login
  canResetOnFirstLogin(): boolean {
    if(this.old_mot_passe === undefined || this.old_mot_passe === null || this.old_mot_passe.trim() === ""){
      this.errorMessage = "Mot de passe actuel manquant"; return false;
    }

    if(this.new_mot_passe === undefined || this.new_mot_passe === null || this.new_mot_passe.trim() === ""){
      this.errorMessage = "Nouveau Mot de passe manquant"; return false;
    }

    if(this.new_mot_passe_confirmation === undefined || this.new_mot_passe_confirmation === null || this.new_mot_passe_confirmation.trim() === ""){
      this.errorMessage = "Confirmation de mot de passe manquante"; return false;
    }
    if(this.new_mot_passe !== this.new_mot_passe_confirmation){
      this.errorMessage = "Le mot de passe et sa confirmation doivent être identiques"; return false;
    }

    if(this.new_mot_passe.length < 5){
      this.errorMessage = "Le Mot de passe de confirmation doit contenir au moins 5 caractères"; return false;
    }

    return true;
  }//end canResetOnFirstLogin

}
