import {groupe} from "./groupe";
import {structure} from "./structure";
import * as EmailValidator from 'email-validator';
import {utilisateur} from "./utilisateur";
import {parcours_academique} from "./parcours_academique";
import {qualification_profesionnelle} from "./qualification_professionnelle";


export class mdscform {

    public identite: utilisateur;
    public profil: any;

    public parcours: parcours_academique[];
    public oldParcours: any[];

    public qualifications: qualification_profesionnelle[];
    public oldQualifications: any[];

    public annees_experience: number;
    public poolsTechniques: any[];
    public domainesExpPrio: any[];
    public autreDomaineExp: string;
    public autreDomaineExp2: string;

    public languesEtrangeres: any[];
    public autrelangueEtrangere: string;
    public languesLocales: any[];
    public autreLangueLocale: string;

    public competencesInformatik: any[];
    public autreCompetenceInformatik: string;
    public outilsCollectes: any[];
    public autreOutilCollecte: string;


    public cv: any;
    public references: any[];

    public step: any;

    public poolThematique_1: any;
    public poolThematique_2: any;
    public poolThematique_3: any;
    public detailsPoolsThematiques: any;

    public domaineExpertise_1: any;
    public domaineExpertise_2: any;
    public domaineExpertise_3: any;

    public langueEtrangere_1: any;
    public langueEtrangere_2: any;

    public langueLocale_1: any;
    public langueLocale_2: any;
    public langueLocale_3: any;

    public competence_1: any;
    public competence_2: any;
    public competence_3: any;



    private errorMessage: string;

    constructor(){
        this.errorMessage = "";
        this.identite = new utilisateur();
        this.annees_experience = 0;
        this.competencesInformatik = [];
        this.domainesExpPrio = [];
        this.poolsTechniques = [];
        this.languesEtrangeres = [];
        this.languesLocales = [];
        this.outilsCollectes = [];
        this.parcours = [];
        this.qualifications = [];
        this.step = "step1";
        this.detailsPoolsThematiques = [];

        //init selection lists
      this.poolThematique_1 = {};
      this.poolThematique_2 = {};
      this.poolThematique_3 = {};

      this.domaineExpertise_1 = {};
      this.domaineExpertise_2 = {};
      this.domaineExpertise_3 = {};

      this.langueEtrangere_1 = {};
      this.langueEtrangere_2 = {};

      this.langueLocale_1 = {};
      this.langueLocale_2 = {};
      this.langueLocale_3 = {};

      this.competence_1 = {};
      this.competence_2 = {};
      this.competence_3 = {};
    }

    //get error message
    getErrorMessage(): string {
        return this.errorMessage;
    }//end getErrorMessage

  //prepare for save
  prepareObjectForSave(): void {

      //les thematiques
      this.poolsTechniques = [];
      //thematique 1
      if( (this.poolThematique_1 !== undefined) && (this.poolThematique_1 !== null) && (this.poolThematique_1.id !== undefined) ) {
        this.poolsTechniques.push(this.poolThematique_1);
      }
      //thematique 2
      if( (this.poolThematique_2 !== undefined) && (this.poolThematique_2 !== null) && (this.poolThematique_2.id !== undefined)  ) {
        this.poolsTechniques.push(this.poolThematique_2);
      }
      //thematique 3
      if( (this.poolThematique_3 !== undefined) && (this.poolThematique_3 !== null) && (this.poolThematique_3.id !== undefined)  ) {
        this.poolsTechniques.push(this.poolThematique_3);
      }


    //les domaines dexpertise
    this.domainesExpPrio = [];
    //domaines dexpertise 1
    if( (this.domaineExpertise_1 !== undefined) && (this.domaineExpertise_1 !== null) && (this.domaineExpertise_1.id !== undefined)  ) {
      this.domainesExpPrio.push(this.domaineExpertise_1);
    }
    //domaines dexpertise 2
    if( (this.domaineExpertise_2 !== undefined) && (this.domaineExpertise_2 !== null) && (this.domaineExpertise_2.id !== undefined)  ) {
      this.domainesExpPrio.push(this.domaineExpertise_2);
    }
    //domaines dexpertise 3
    if( (this.domaineExpertise_3 !== undefined) && (this.domaineExpertise_3 !== null) && (this.domaineExpertise_3.id !== undefined)  ) {
      this.domainesExpPrio.push(this.domaineExpertise_3);
    }

    //les langues etrangeres
    this.languesEtrangeres = [];
    //langue 1
    if( (this.langueEtrangere_1 !== undefined) && (this.langueEtrangere_1 !== null) && (this.langueEtrangere_1.id !== undefined)  ) {
      this.languesEtrangeres.push(this.langueEtrangere_1);
    }
    //langue 2
    if( (this.langueEtrangere_2 !== undefined) && (this.langueEtrangere_2 !== null) && (this.langueEtrangere_2.id !== undefined)  ) {
      this.languesEtrangeres.push(this.langueEtrangere_2);
    }

    //les langues locales
    this.languesLocales = [];
    //langue 1
    if( (this.langueLocale_1 !== undefined) && (this.langueLocale_1 !== null) && (this.langueLocale_1.id !== undefined)  ) {
      this.languesLocales.push(this.langueLocale_1);
    }
    //langue 2
    if( (this.langueLocale_2 !== undefined) && (this.langueLocale_2 !== null) && (this.langueLocale_2.id !== undefined)  ) {
      this.languesLocales.push(this.langueLocale_2);
    }
    //langue 3
    if( (this.langueLocale_3 !== undefined) && (this.langueLocale_3 !== null) && (this.langueLocale_3.id !== undefined)  ) {
      this.languesLocales.push(this.langueLocale_3);
    }

    //les competences
    this.competencesInformatik = [];
    //competence 1
    if( (this.competence_1 !== undefined) && (this.competence_1 !== null) && (this.competence_1.id !== undefined)  ) {
      this.competencesInformatik.push(this.competence_1);
    }
    //competence 2
    if( (this.competence_2 !== undefined) && (this.competence_2 !== null) && (this.competence_2.id !== undefined)  ) {
      this.competencesInformatik.push(this.competence_2);
    }
    //competence 3
    if( (this.competence_3 !== undefined) && (this.competence_3 !== null) && (this.competence_3.id !== undefined)  ) {
      this.competencesInformatik.push(this.competence_3);
    }

  }//end prepareObjectForSave


  //check for save
  canSave(): boolean {

      //step 1
    if(!this.identite.canSave()){
      this.errorMessage = this.identite.getErrorMessage(); return false;
    }

    //step 2
    if(this.step === "step2"){
      if( (this.parcours === undefined || this.parcours === null || this.parcours.length === 0) &&
        (this.oldParcours === undefined || this.oldParcours === null || this.oldParcours.length === 0)   ){
        this.errorMessage = "Veuillez ajouter au moins un parcours académique avant tout enregistrement (onglet 2)"; return false;
      }
    }

    //step 3
    if(this.step === "step3"){
        if( (this.qualifications === undefined || this.qualifications === null || this.qualifications.length === 0) &&
          (this.oldQualifications === undefined || this.oldQualifications === null || this.oldQualifications.length === 0)   ){
          this.errorMessage = "Veuillez ajouter au moins une qualification professionnelle avant tout enregistrement (onglet 3)"; return false;
        }
    }

    //step 4
    if(this.step === "step4"){
        if(this.annees_experience === undefined || this.annees_experience === null || this.annees_experience === 0){
          this.errorMessage = "Veuillez indiquer le nombre d'années d'expérience avant tout enregistrement (onglet 4)"; return false;
        }

        if(this.detailsPoolsThematiques.length === 0 ){
            this.errorMessage = "Veuillez ajouter au moins un pool thématique avant tout enregistrement (onglet 4)"; return false;
        }

        //if(this.poolThematique_1 === undefined || this.poolThematique_1 === null || this.poolThematique_1.id === undefined ){
        //  this.errorMessage = "Veuillez ajouter au moins un pool thématique avant tout enregistrement (onglet 4)"; return false;
        //}

      //prevent same selection pool thematique for all
      //if( ( this.poolThematique_1.id!== undefined && this.poolThematique_2.id!== undefined && this.poolThematique_1.id === this.poolThematique_2.id)
       // || ( this.poolThematique_1.id!== undefined && this.poolThematique_3.id!== undefined &&  this.poolThematique_1.id === this.poolThematique_3.id)
       // || ( this.poolThematique_2.id!== undefined && this.poolThematique_3.id!== undefined &&  this.poolThematique_2.id === this.poolThematique_3.id) ){
       // this.errorMessage = "Vos choix de pools thématiques ne peuvent pas être identiques "; return false;
      //}//

        if(this.domaineExpertise_1 === undefined || this.domaineExpertise_1 === null || this.domaineExpertise_1.id === undefined ){
          this.errorMessage = "Veuillez ajouter au moins un domaine d'expertise prioritaire avant tout enregistrement (onglet 4)"; return false;
        }

         //prevent same selection domaineExpertise for all
         if( ( this.domaineExpertise_1.id!== undefined && this.domaineExpertise_2.id!== undefined && this.domaineExpertise_1.id === this.domaineExpertise_2.id)
           || ( this.domaineExpertise_1.id!== undefined && this.domaineExpertise_3.id!== undefined && this.domaineExpertise_1.id === this.domaineExpertise_3.id)
           || ( this.domaineExpertise_2.id!== undefined && this.domaineExpertise_3.id!== undefined && this.domaineExpertise_2.id === this.domaineExpertise_3.id) ){
           this.errorMessage = "Vos choix de domaines d'expertise ne peuvent pas être identiques "; return false;
         }//
    }//end step 4

    //step 5
    if(this.step === "step5"){
        if(this.langueEtrangere_1 === undefined || this.langueEtrangere_1 === null || this.langueEtrangere_1.id === undefined ){
          this.errorMessage = "Veuillez ajouter au moins une langue étrangère avant tout enregistrement (onglet 5)"; return false;
        }

      //prevent same selection langueEtrangere for all
      if( (this.langueEtrangere_1.id!== undefined && this.langueEtrangere_2.id!== undefined &&  this.langueEtrangere_1.id === this.langueEtrangere_2.id) ){
        this.errorMessage = "Vos choix de langues étrangères ne peuvent pas être identiques "; return false;
      }//

        if(this.langueLocale_1 === undefined || this.langueLocale_1 === null || this.langueLocale_1.id === undefined){
          this.errorMessage = "Veuillez ajouter au moins une langue locale avant tout enregistrement (onglet 5)"; return false;
        }

      //prevent same selection langueLocale for all
      if( (this.langueLocale_1.id!== undefined && this.langueLocale_2.id!== undefined && this.langueLocale_1.id === this.langueLocale_2.id)
        || (this.langueLocale_1.id!== undefined && this.langueLocale_3.id!== undefined && this.langueLocale_1.id === this.langueLocale_3.id)
        || (this.langueLocale_2.id!== undefined && this.langueLocale_3.id!== undefined && this.langueLocale_2.id === this.langueLocale_3.id) ){
        this.errorMessage = "Vos choix de langues locales ne peuvent pas être identiques "; return false;
      }//

        if(this.competence_1 === undefined || this.competence_1 === null || this.competence_1.id === undefined ){
          this.errorMessage = "Veuillez ajouter au moins une compétence informatique avant tout enregistrement (onglet 5)"; return false;
        }

      //prevent same selection competence for all
      if( (this.competence_1.id!== undefined && this.competence_2.id!== undefined && this.competence_1.id === this.competence_2.id)
        || (this.competence_1.id!== undefined && this.competence_3.id!== undefined && this.competence_1.id === this.competence_3.id)
        || (this.competence_2.id!== undefined && this.competence_3.id!== undefined && this.competence_2.id === this.competence_3.id) ){
        this.errorMessage = "Vos choix de compétences informatiques ne peuvent pas être identiques "; return false;
      }//
    }

    return true;
  }//end canSave


  //check step 1
  isStep1Ok(item: any): boolean {
    if ( (item.identite.nom !== undefined) && (item.identite.prenoms !== undefined) && (item.identite.date_naissance !== undefined) && (item.identite.telephone_1 !== undefined)
      && (item.identite.id_commune_residence !== undefined && item.identite.id_commune_residence !== null) &&  (item.adresse_mail !== undefined) ) {
      return true;
    }
    return false;
  }//end isStep1Ok

  //check step 2
  isStep2Ok(item: any): boolean {
      if(item.identite.parcours_academique_consultants.length > 0) { return true; }
    return false;
  }//end isStep2Ok

  //check step 3
  isStep3Ok(item: any): boolean {
    if(item.identite.qualification_pro_consultants.length > 0) { return true; }
    return false;
  }//end isStep3Ok

  //check step 4
  isStep4Ok(item: any): boolean {
    if( (item.identite.annees_experience) !== undefined && (item.identite.annees_experience !== null) && (item.identite.annees_experience !== 0)
      &&  (item.identite.detail_pool_technique_consultants.length > 0)    &&  (item.identite.expertise_consultants.length > 0)
    ){
      return true;
    }
    return false;
  }//end isStep4Ok

  //check step 5
  isStep5Ok(item: any): boolean {
    if(  (item.identite.langue_consultants.length > 1)    &&  (item.identite.outil_informatique_consultants.length > 0)
    ){
      return true;
    }
    return false;
  }//end isStep5Ok

  //check step 6
  isStep6Ok(item: any): boolean {
    if(  (item.identite.ressources_consultants.length > 1))
    {
      return true;
    }
    return false;
  }//end isStep6Ok




}
