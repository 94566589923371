import { Pipe, PipeTransform } from '@angular/core';
import {LibraryService} from "../../services/app/library.service";
import {ConstantsService} from "../../services/general/constants.service";

@Pipe({
  name: 'formatToMonth'
})
export class FormatToMonthPipe implements PipeTransform {

  constructor(private libServ: LibraryService, private constant: ConstantsService) {}

    transform(value: Date, args: any) {
    let theDate: Date = new Date();
    theDate = this.libServ.convertStrDateToJsDate(value);
      if(value === undefined) return "";
      let result: string  = this.constant.MONTH_LIST[theDate.getMonth()]  + "-" + theDate.getFullYear();
        return result; //
    }

}
