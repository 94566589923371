import { Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import * as moment from 'moment';
import {ConstantsService} from "../../../shared/services/general/constants.service";
import {LibraryService} from "../../../shared/services/app/library.service";
//import {isDate} from "rxjs/util/isDate";

@Component({
  selector: 'it-datepicker',
  templateUrl: './it.datepicker.component.html',
  styleUrls: ['./it.datepicker.component.css']
})

export class ItDatePickerComponent implements  OnInit{

  @Input() public dt: any;
  //public dtFormatted: string;
  fr: any;

  //private opened: boolean = false;
  private showDatePicker = false;
  @Output() selected = new EventEmitter<any>();

  public constructor(private constant: ConstantsService, private libraryService: LibraryService
  ) {

  }

  ngOnInit(): void{
    if(this.dt === null || this.dt === undefined){
      //this.dt = new Date();
    }else{
      // if(!isDate(this.dt)){
      //   this.dt = new Date(); //this.libraryService.convertStrDateToJsDate(this.dt);
      // }
    }

    this.selected.emit(this.dt);
    this.fr = {
      firstDayOfWeek: 0,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
      monthNames : this.constant.MONTH_LIST, // [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aôut","Septembre","Octobre","Novembre","Décembre" ],
      monthNamesShort: [ "Jan", "Fev", "Mar", "Avr", "Mai", "Ju","Jui", "Au", "Sep", "Oct", "Nov", "Dec" ]
    };
  }


  handleSelection(event: any): void{
    this.dt = event;
    this.selected.emit(event);
  }

  //date typed
  handleDateInput(event: any): void{
    let resultdateCheck = this.libraryService.isValidDate(this.dt);

    if(resultdateCheck === true){
      //this.dt = event;
      this.selected.emit(this.dt);
    }else{
      //console.log("not valid");
    }
  }//end handleDateInput


  public open(): void {
    //this.opened = !this.opened;
  }


}
