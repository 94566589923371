


export class parcours_academique {

  public id: number;
  public niveau_qualification: any;
  public domaine_etude: string;
  public debut: any;
  public fin: any;

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {

    if(this.niveau_qualification === undefined || this.niveau_qualification === null){
        this.errorMessage = "Veuillez indiquer le niveau de qualification"; return false;
    }
    if(this.domaine_etude === undefined || this.domaine_etude === null || this.domaine_etude.trim() === ""){
        this.errorMessage = "Veuillez indiquer le domaine d'étude"; return false;
    }
    if(this.debut === undefined || this.debut === null){
        this.errorMessage = "Veuillez indiquer la date de début"; return false;
    }
    if(this.fin === undefined || this.fin === null){
        this.errorMessage = "Veuillez indiquer la date de fin"; return false;
    }

      return true;
  }//end canSave


}
