import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SecuService} from "../../../shared/services/secu/secu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {LibraryService} from "../../../shared/services/app/library.service";
import {ParamService} from "../../../shared/services/param/param.service";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../shared/services/general/utils.service";
import {ConfirmationService} from "primeng/api";
import {utilisateur} from "../../../shared/model/utilisateur";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {LocalStorageService} from "../../../shared/services/app/localstorage.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset.password.component.html',
  styleUrls: ['./reset.password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  newUser : utilisateur = new utilisateur();
  connUser: utilisateur = new utilisateur(); 

  constructor(private router: Router, private paramService: ParamService, private libraryService: LibraryService,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService , private utils: UtilsService,
			  private localStorage: LocalStorageService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.connUser = this.localStorage.getUser();
	this.newUser.adresse_mail = this.connUser.adresse_mail;
  }



  //save request
  saveRequestChangePasswordInternal(): void {
    if(!this.newUser.canResetOnFirstLogin()){
      this.toastr.error(this.newUser.getErrorMessage(), "Connexion", {positionClass: 'toast-top-center'});
      return;
    }
    this.spinner.show();
    this.authService.resetOnFirstLogin(this.newUser).subscribe((result) => {
      this.spinner.hide();
		//check the result 
		if(result === undefined || result === null){
			      let error: string = "Une erreur est survenue. Veuillez vérifier votre connexion puis réessayer";
				  this.toastr.error(error, "Réinitialisation", {positionClass: 'toast-top-center'});
				  return;
		}
	  
		//check the result
      if(result.status === "error"){
        let error: string = result.message;
        this.toastr.error(error, "Réinitialisation", {positionClass: 'toast-top-center'});
        return;
      }else{
        this.newUser.adresse_mail = "";
        let error: string = result.message;
        this.toastr.success(error, "Réinitialisation", {positionClass: 'toast-top-center'});
        return;
      }
    })
  }//end saveRequestChangePassword
  
  
    //back
  back(): void {
    this.router.navigate(["/"]);
  }//end back



}
