import { Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import * as moment from 'moment';
import {ConstantsService} from "../../../shared/services/general/constants.service";
import {Router} from "@angular/router";


@Component({
  selector: 'public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css']
})

export class PublicHeaderComponent implements  OnInit{


  public constructor(private router: Router ) {

  }

  ngOnInit(): void{
  }


  //connecter
  connect(): void{
    this.router.navigate(["/login"]);
  }//end connecter
  
    //register
  register(): void{
    this.router.navigate(["/register"]);
  }//end connecter
  
  //go home
  goHome(): void {
    this.router.navigate(["/"]);
  }//end goHome
  
    //showExperts
  showExperts(): void {
    this.router.navigate(["/dashboard_competences"]);
  }//end showExperts
  
    //showAdminLogin
  showAdminLogin(): void {
    this.router.navigate(["/adminlogin"]);
  }//end showAdminLogin




}
