import { Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import * as moment from 'moment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Router} from "@angular/router";
import {LocalStorageService} from "../../../shared/services/app/localstorage.service";


@Component({
  selector: 'generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.css']
})

export class GenericModalComponent implements  OnInit{


  public constructor(public activeModal: NgbActiveModal, private storage: LocalStorageService) {

  }

  @Input() title;
  @Input() message;
  @Input() status;
  //@Input() response;
  @Output() aClickedEvent = new EventEmitter<any>();

  ngOnInit(): void{
  }


  //action
  action(status: any): void {
    this.activeModal.close('');
    this.aClickedEvent.emit(status);
  }//end action




}
