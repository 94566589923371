/**
 * Created by utilisateur on 31/03/2017.
 */

/**
 * Created by utilisateur on 29/03/2017.
 */


import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";




@Injectable()
export class LocalStorageService {



  constructor(private http: HttpClient) {}

    //enregistre un utilisateur connecte
    public saveUser (obj: any): void{
    this.saveToSession("M_MAC_MDSC_APP_USER", obj);
  };
    //retourne un user
    public getUser (): any {
    return this.readFromSession("M_MAC_MDSC_APP_USER");
  };
  
  
      //enregistre une ligne dexpert
    public saveLineExpertData (obj: any): void{
		this.saveToSession("M_MAC_MDSC_APP_EXPERT_LINE", obj);
  };
    //retourne une ligne dexpert
    public getLineExpertData (): any {
		return this.readFromSession("M_MAC_MDSC_APP_EXPERT_LINE");
  };
  
  


  //enregistre une reponse modale
  public saveModalAnswer (obj: any): void{
    this.saveToSession("M_MAC_MDSC_MODAL_ASWER", obj);
  };//end saveModalAnswer

  //retourne une reponse modale
  public getModalAnswer (): any {
    return this.readFromSession("M_MAC_MDSC_MODAL_ASWER");
  };












  private saveToSession (key: string, value: any): void{
    var stringified = JSON.stringify(value);
    var jsonValue = btoa(stringified);
    localStorage.setItem(key, jsonValue);
  };

  private readFromSession (key: any): any{
    var result = null;
    try{
      var json = localStorage.getItem(key);
      var result = JSON.parse(atob(json));
    }catch(e){
      result = null;
    }
    return result;
  };

}

