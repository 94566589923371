import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {NgbDatepickerModule, NgbModalModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

import { ComponentsModule } from './components/components.module';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {ToastrModule} from "ngx-toastr";
import {NgxSpinnerModule} from "ngx-spinner";
import {AuthService} from "./shared/services/auth/auth.service";
import {AppStorageService} from "./shared/services/general/appstorage.service";
import {StorageServiceModule, WebStorageService} from 'angular-webstorage-service';
import {ParamService} from "./shared/services/param/param.service";
import {ConfirmationService} from "primeng/api";
import {ConstantsService} from "./shared/services/general/constants.service";

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {AppService} from "./shared/services/app/app.service";
import {LoginComponent} from "./components/auth/login/login.component";
import {UtilsService} from "./shared/services/general/utils.service";
import {SecuService} from "./shared/services/secu/secu.service";
import {OperationService} from "./shared/services/operation/operation.service";
//import {PublicDashboardComponent} from "./components/main/home/public/publicdashboard.component";
import { AdminLoginComponent } from "./components/auth/admin_login/admin_login.component";


import {
  AccordionModule,
  ButtonModule,
  ChartModule, ConfirmDialogModule, FileUploadModule, GrowlModule,
  InputTextModule,
  MessagesModule,
  PaginatorModule, TabViewModule,
} from "primeng/primeng";
import {LibraryService} from "./shared/services/app/library.service";
import {LocalStorageService} from "./shared/services/app/localstorage.service";
import {ReportService} from "./shared/services/report/report.service";
import {CarouselModule} from "ngx-owl-carousel-o";
import {DialogModule} from "primeng/dialog";

import {CalendarModule} from "primeng/calendar";
import {PublicLayoutModule} from "./layouts/public-layout/public-layout.module";
import {PublicLayoutComponent} from "./layouts/public-layout/public-layout.component";

import {TableModule} from "primeng/table";
import {InputMaskModule} from 'primeng/inputmask';
import {ChangePasswordComponent} from "./components/auth/change/change.password.component";
import {ResetPasswordComponent} from "./components/auth/reset/reset.password.component";

import {SharedModule} from './shared/modules/shared.module';
import {AccueilCompetenceComponent} from "./components/public/competences/accueil/accueil_competence.component";
import {SoumettreCompetenceComponent} from "./components/public/competences/soumettre/soumettre_competence.component";
import {RechercherCompetenceComponent} from "./components/public/competences/rechercher/rechercher_competence.component";
import {RegisterComponent} from "./components/auth/register/register.component";
import {GenericModalComponent} from "./components/utils/generic-modal/generic-modal.component";
import {StatsGenComponent} from "./components/public/competences/stats_gen/stats_gen.component";
import {DashBoardCompetencesComponent} from "./components/public/competences/dashboard/dashboard_competences.component";
//import { DialogModule } from '@syncfusion/ej2-angular-popups;
import { EmbedVideo } from 'ngx-embed-video';
import { DetailCompetenceComponent } from './components/public/competences/detail/detail_competence.component';

import {ReglesConfidentialiteComponent} from "./components/public/competences/regles_confidentialite/regles_confidentialite.component";
import {ConditionsUtilisationComponent} from "./components/public/competences/conditions_utilisation/conditions_utilisation.component";
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';



const cookieConfig:NgcCookieConsentConfig = 
{
  "cookie": {
    "domain": "localhost"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff"
    },
    "button": {
      "background": "#F4A53A",
      "text": "#000000"
    }
  },
  "type": "info",
  "content": {
    "message": "Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. En cliquant sur ”J’accepte”, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement. ",
    "dismiss": "J'accepte",
    "deny": "Je Refuse ",
    "link": "",
    "href": "",
    "policy": "Cookie Policy"
  }
};


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent, PublicLayoutComponent,
    LoginComponent,  ChangePasswordComponent,


    AccueilCompetenceComponent, SoumettreCompetenceComponent, RechercherCompetenceComponent, RegisterComponent,
    GenericModalComponent, StatsGenComponent, 
	DashBoardCompetencesComponent, 
	DetailCompetenceComponent,
	ResetPasswordComponent,
	AdminLoginComponent,
	ReglesConfidentialiteComponent,
	ConditionsUtilisationComponent

  ],
  entryComponents: [
    GenericModalComponent, DetailCompetenceComponent,
  ],

  imports: [
    BrowserAnimationsModule,

    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    DialogModule,
    AppRoutingModule,
    NgbCarouselModule.forRoot(),

    EmbedVideo.forRoot(),
	NgcCookieConsentModule.forRoot(cookieConfig),

    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-center',
        closeButton: true
      }
    ),
    NgxSpinnerModule, StorageServiceModule,
    NgbModalModule.forRoot(), NgbDatepickerModule.forRoot(),
    TabViewModule,
    //NgbModule,

    SharedModule,

    CarouselModule, InputMaskModule,
    InputTextModule, ButtonModule, CalendarModule, PaginatorModule, ChartModule, MessagesModule, ConfirmDialogModule,
    GrowlModule, FileUploadModule, TableModule, AccordionModule
  ],
  providers: [ UtilsService, AuthService, AppStorageService, ParamService,  ConfirmationService,
    ConstantsService,  AppService, SecuService, OperationService, LibraryService, LocalStorageService, ReportService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
