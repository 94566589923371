import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itPaginator'
})
export class ItPaginatorPipe implements PipeTransform {

  transform(items: any[], startIndex: number, len: number) {
    if (!items || (!startIndex && !len )) {
      return items;
    }

    if(items.length === undefined){
      return [];
    }else{
      return items
        .slice(startIndex, startIndex + len);
    }


  }//end transform

}
