import {type_structure} from "./type_structure";
import {localisation} from "./localisation";
import {niveau_hierarchique} from "./niveau_hierarchique";



export class structure {

  public id: number;
  public designation: string;

  public type_structure_id: number;
  public type_structure: type_structure;
  public type_structure_name: string;

  public localisation_id: number;
  public localisation: localisation;
  public localisation_name: string;

  public niveau_hierarchique_id: number;
  public niveau_hierarchique: niveau_hierarchique;
  public niveau_hierarchique_name: string;

  public ou: string;
  public qui: string;
  public quand: string;

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {
      if(this.designation === undefined || this.designation === null || this.designation.trim() === ""){
          this.errorMessage = "Veuillez renseigner la désignation"; return false;
      }
      if(this.type_structure === undefined || this.type_structure === null){
        this.errorMessage = "Veuillez renseigner le type de structure"; return false;
      }
      if(this.localisation === undefined || this.localisation === null ){
        this.errorMessage = "Veuillez sélectionner la commune"; return false;
      }
      if(this.niveau_hierarchique === undefined || this.niveau_hierarchique === null ){
        this.errorMessage = "Veuillez sélectionner le niveau hiérarchique"; return false;
      }

      return true;
  }//end canSave


}
