import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {ReportService} from "../../../../shared/services/report/report.service";
import {structure} from "../../../../shared/model/structure";
import {ParamService} from "../../../../shared/services/param/param.service";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {utilisateur} from "../../../../shared/model/utilisateur";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as FileSaver from 'file-saver';
import {localisation} from "../../../../shared/model/localisation";
import { ActivatedRoute } from '@angular/router';
import {LibraryService} from "../../../../shared/services/app/library.service";
import {AppService} from     "../../../../shared/services/app/app.service";

@Component({
  selector: 'app-dashboard-competences',
  templateUrl: './dashboard_competences.component.html',
  styleUrls: ['./dashboard_competences.component.css']
})
export class DashBoardCompetencesComponent implements OnInit {

  constructor(private router: Router, private reportService: ReportService, private libraryService: LibraryService,
              private paramService: ParamService, private secuService: SecuService,
			  private appService: AppService,
              private localStorage: LocalStorageService, private spinner: NgxSpinnerService,
              private toastr: ToastrService , private utils: UtilsService, private route: ActivatedRoute)
  {
  }


  departements: any[];   paramSearch: any = {};
  communes: any[] = []; domainesExpertise: any[] = []; filteredCommunes: any[] = [];
  poolThematiques: any[] = [];
  selectedDepartement: localisation;  selectedCommune: localisation;
  connUser: utilisateur;
  codeIndic: string;   private sub: any;
  isLoading: boolean = false;
  niveauxQualification: any[] = []; 
  expertDetail: any = null;

  experts: any[] = [];
  displayDetailForm:  boolean = false;
  isMoreCriteriaDisplayed:  boolean = false;
  resourceBaseUrl: any = "";
  
  //toogle more criteria
  toogleMoreCriteria(){
	  this.isMoreCriteriaDisplayed = !this.isMoreCriteriaDisplayed;
  }//end toogleMoreCriteria


  pageStartIndex : number = 0; limitTable: number[] = []; pageLimit: number = 0;

  trackByFn(): void {}



  ngOnInit() {
	this.resourceBaseUrl = this.appService.getBaseUrl();
		  
    this.sub = this.route.params.subscribe(params => {
      this.codeIndic = params['code'];
    });
    this.connUser = this.localStorage.getUser();
    this.paramSearch = { startDate: new Date(), endDate: new Date() };
    this.limitTable = this.libraryService.getPaginatorLimitList(); this.pageLimit = this.libraryService.getPaginatorDefaultLimit();

    //get experts
    this.getListExpertises();

    //load param data
    this.getListCommunes();
    this.getListDepartements();
    this.getExpertisesPrio();
    this.getListPoolsThematiques();
    this.getNiveauxQualification();

  }//end oninit

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  //recherche des compétences
  searchData(): void {
      this.getListExpertises();
  }//end searchData


  //edit item
  editItemCompetence(item: any){
	  let message: string = "Veuillez vous rapprocher de la Maison de la Société Civile pour plus de détails cet expert";
	  this.toastr.info(message, "MdSC Expertise Plus", {positionClass: 'toast-top-center'});
	  return;
	  
	  //remove all this 
    this.spinner.show();
    this.displayDetailForm = false;

    console.log(item);

    this.paramService.getExpertDetailData(item).subscribe(
      (result) => {

        this.displayDetailForm = true;

        this.spinner.hide();
          if(result.status === "error"){
            let error: string = result.message;
            this.toastr.error(error, "Détail Compétence MdSC", {positionClass: 'toast-top-center'});
            return;
          }
          this.expertDetail = result.data;
      }, 
      (error) => {
        this.displayDetailForm = false;
        this.spinner.hide();
        alert("Une erreur est survenue");
      }
    )
  }//end editItemCompetence


  //remove competence test
  removeItemCompetence(item: any){

    // console.log(item);
    // this.spinner.show();

    // this.paramService.removeExpertise(item).subscribe(
    //   (result) => {

    //     this.spinner.hide();
    //       if(result.status === "error"){
    //         let error: string = result.message;
    //         this.toastr.error(error, "Suppression de Competences test", {positionClass: 'toast-top-center'});
    //         return;
    //       }
    //       this.searchData();
    //   }, 
    //   (error) => {
    //     this.spinner.hide();
    //     alert("Une erreur est survenue");
    //   }
    // )
  }//end removeItemCompetence


  //get list departements
  getListExpertises(): void {
    this.experts = [];
    this.isLoading = true;
    this.paramService.getExpertsDashBoardData(this.paramSearch).subscribe(
      (result) => {
          this.isLoading = false;
		  if(result === null || result === undefined){
			  this.experts = []; return;
		  }

          if(result.status === "error"){
            alert(result.message); return;
          }else{
				this.experts = result.data;
		  }
      }, 
      (error) => {
          this.isLoading = false;
        alert("Une erreur est survenue");
      }
    )
  }//end getListExpertises



    //get niveau de qualification
    getNiveauxQualification(): void {
      this.paramService.getNiveauxQualification().subscribe((result) => {
		  //if(result.status === "error"){ return;  }
          this.niveauxQualification = result;
      })
    }//end getNiveauxQualification


  //date de debut
  getSelectedDateStart(event: Date){
    if(event !== undefined) event.setHours(new Date().getHours());
    this.paramSearch.startDate = event;
  }

  //date de fin
  getSelectedDateEnd(event: Date){
    if(event !== undefined) event.setHours(new Date().getHours());
    this.paramSearch.endDate = event;
  }

  //init reset form
  initForm(): void {
    this.selectedDepartement = null;  this.paramSearch.commune = null;
    this.paramSearch.structure = null;
    this.paramSearch.startDate = null; this.paramSearch.endDate = null;
  }//end initForm

  //paginate on page change
  paginate(event: any): void{
    this.pageStartIndex = event.first;
    this.pageLimit = event.rows;
  }//fin


  //get list departements
  getListDepartements(): void {
    this.paramService.getDepartements().subscribe((result) => {
		//if(result.status === "error"){  return;  }
      this.departements = result;
    })
  }//end getListDepartements

  //departement changed
  departementChanged() : void {
    this.filteredCommunes = this.communes.filter(x => x.id_departement === this.paramSearch.departement.id);
    this.paramSearch.commune = {};
  }//end departementChanged

  //get list communes
  getListCommunes(): void {
    this.paramService.getCommunes().subscribe((result) => {
	  //if(result.status === "error"){  return;  }
      this.communes = result;
    })
  }//end getListCommunes

  //get list pools thematiques
  getListPoolsThematiques(): void {
    this.paramService.getDetailsPoolsTechniques().subscribe((result) => {
		//if(result.status === "error"){  return;  }
      this.poolThematiques = result;
      // for(let i: number = 0; i < this.poolExpertises.length; i++ ){
      //   this.poolExpertises[i]["checked"] = false;
      // }
    })
  }//end getListPoolsThematiques

  //get list expertise prio
  getExpertisesPrio(): void {
    this.paramService.getExpertisesPrio().subscribe((result) => {
	  if(result === undefined || result === null){ this.domainesExpertise = []; return;  }
      this.domainesExpertise = result;

      for(let i: number = 0; i < this.domainesExpertise.length; i++ ){
        this.domainesExpertise[i]["checked"] = false;
      }
    })
  }//end getExpertisesPrio
  
  //open competence 
  openCompetence(item: any){
	  
	  	  //remove all this 
    this.spinner.show();
    this.displayDetailForm = false;

    this.paramService.getExpertDetailData(item).subscribe(
      (result) => {

        this.spinner.hide();
          if(result.status === "error"){
            let error: string = result.message;
            this.toastr.error(error, "Détail Compétence MdSC", {positionClass: 'toast-top-center'});
            return;
          }
		  
          let competence = result.data;
		  
		  let listResourcesCV = competence.ressources_consultants.filter(x => x.id_type_ressource === 1);
		  
		  let finalCV: any;
		  if(listResourcesCV.length > 0) {
			  finalCV = listResourcesCV[0];
			  let url: any = this.resourceBaseUrl + "images/" + finalCV.lien_fichier;
			  
			   window.open(url,'_blank');
		  }else{
			  this.toastr.error("Ce consultant n'a pas enregistré son CV", "MdSC Expertise Plus", {positionClass: 'toast-top-center'});
			  return;
		  }
		  
      }, 
      (error) => {
        
        this.spinner.hide();
        alert("Une erreur est survenue");
      }
    )
	  
  }//end openCompetence

}
