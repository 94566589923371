


export class groupe {

  public id: number;
  public designation: string;
  public ou: string;
  public qui: string;
  public quand: string;

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {
      if(this.designation === undefined || this.designation === null || this.designation.trim() === ""){
          this.errorMessage = "Désignation manquante"; return false;
      }

      return true;
  }//end canSave


}
