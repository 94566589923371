 import { Injectable } from '@angular/core';
 import {donnee_collectee} from "../../model/donnee_collectee";
 import {FS_PSSN_Detail} from "../../model/FS_PSSN_Detail";
 import {type_structure} from "../../model/type_structure";
 import {structure} from "../../model/structure";
 import {groupe} from "../../model/groupe";
 import {utilisateur} from "../../model/utilisateur";
 import {FS_PSSN} from "../../model/FS_PSSN";
 import {FS_PE} from "../../model/FS_PE";
 import {FS_PE_Detail} from "../../model/FS_PE_Detail";
 import {FS_PF_FG_Detail} from "../../model/FS_PF_FG_Detail";
 import {FS_PF_FG} from "../../model/FS_PF_FG";
 import {ConstantsService} from "./constants.service";
 import {localisation} from "../../model/localisation";


@Injectable()
export class UtilsService {

    private monthList: string[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    constructor() { }

    //recreate typestructure
    public recreateTypeStructure(item: type_structure): type_structure {
      let newItem: type_structure = new type_structure();
      newItem.id = item.id;
      newItem.designation = item.designation;
      newItem.ou = item.ou;
      newItem.quand = item.quand;
      newItem.qui = item.qui;
      return newItem;
    }//end recreateTypeStructure

  //recreate groupe
  public recreateGroupe(item: groupe): groupe {
    let newItem: groupe = new groupe();
    newItem.id = item.id;
    newItem.designation = item.designation;
    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;
    return newItem;
  }//end recreateGroupe

    //recreate structure
    public recreateStructure(item: any): structure {
      let newItem: structure = new structure();
      newItem.id = item.id;
      newItem.designation = item.designation;
      newItem.localisation_id = item.localisation_id;
      newItem.type_structure_id = item.type_structure_id;
      newItem.niveau_hierarchique_id = item.niveau_hierarchique_id;
      newItem.ou = item.ou;
      newItem.quand = item.quand;
      newItem.qui = item.qui;
      return newItem;
    }//end recreateStructure

    //recreate user
    public recreateUser(item: any): utilisateur {
      let newItem: utilisateur = new utilisateur();
      newItem.id = item.id;
      newItem.nom = item.nom;
      newItem.prenoms = item.prenoms;
      newItem.login = item.login;
      //newItem.MotPasse = item.motPasse;
      newItem.adresse_mail = item.adresse_mail;
      newItem.contact = item.contact;
      newItem.groupe_id = item.groupe_id;
      newItem.structure_id = item.structure_id;
      newItem.ou = item.ou;
      newItem.quand = item.quand;
      newItem.qui = item.qui;


      newItem.old_mot_passe = item.old_mot_passe;
      newItem.new_mot_passe = item.new_mot_passe;
      newItem.new_mot_passe_confirmation = item.new_mot_passe_confirmation;

      return newItem;
    }//end recreateUser


    //recreate fiche fspssn
    public recreateFicheFsPssn(item: any): FS_PSSN {
      let newItem: FS_PSSN = new FS_PSSN();

      newItem.id = item.id;
      newItem.numero = item.numero;
      newItem.date_saisie = new Date();
      newItem.date_saisie = item.date_saisie;
      newItem.localisation = item.localisation;
      newItem.localisation_id = item.arrondissement_id;
      newItem.structure = item.structure;
      newItem.structure_id = item.structure_id;

      newItem.date_debut_periode = new Date();
      newItem.date_debut_periode = item.date_debut_periode;

      newItem.date_fin_periode = new Date();
      newItem.date_fin_periode = item.date_fin_periode;

      newItem.statut_fiche_id = item.statut_fiche_id;
      newItem.statut_fiche_name = item.statut_fiche_name;

      newItem.utilisateur = item.utilisateur;
      newItem.utilisateur_id = item.utilisateur_id;
      newItem.service = item.service;
      newItem.motif_rejet = item.motif_rejet;

      newItem.type_donnee_collectee = item.type_donnee_collectee;
      newItem.FS_PSSN_Detail = item.FS_PSSN_Detail;

      newItem.ou = item.ou;
      newItem.quand = item.quand;
      newItem.qui = item.qui;

      return newItem;
    }//end recreateFicheFsPssn


    //recreate fiche fspe
    public recreateFicheFsPe(item: any): FS_PE {
    let newItem: FS_PE = new FS_PE();

    newItem.id = item.id;
    newItem.numero = item.numero;
    newItem.date_saisie = new Date();
    newItem.date_saisie = item.date_saisie;
    newItem.localisation = item.localisation;
    newItem.localisation_id = item.localisation_id;
    newItem.structure = item.structure;
    newItem.structure_id = item.structure_id;

    newItem.date_debut_periode = new Date();
    newItem.date_debut_periode = item.date_debut_periode;

    newItem.date_fin_periode = new Date();
    newItem.date_fin_periode = item.date_fin_periode;

    newItem.statut_fiche_id = item.statut_fiche_id;
    newItem.statut_fiche_name = item.statut_fiche_name;

    newItem.utilisateur = item.utilisateur;
    newItem.utilisateur_id = item.utilisateur_id;
    newItem.service = item.service;
    newItem.motif_rejet = item.motif_rejet;

    newItem.type_donnee_collectee = item.type_donnee_collectee;
    newItem.FS_PE_Detail = item.FS_PE_Detail;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPe

    //recreate fiche fspffg
    public recreateFicheFsPffg(item: any): FS_PF_FG {
    let newItem: FS_PF_FG = new FS_PF_FG();

    newItem.id = item.id;
    newItem.numero = item.numero;
    newItem.date_saisie = new Date();
    newItem.date_saisie = item.date_saisie;
    newItem.localisation = item.localisation;
    newItem.localisation_id = item.arrondissement_id;
    newItem.structure = item.structure;
    newItem.structure_id = item.structure_id;

    newItem.date_debut_periode = new Date();
    newItem.date_debut_periode = item.date_debut_periode;

    newItem.date_fin_periode = new Date();
    newItem.date_fin_periode = item.date_fin_periode;

    newItem.statut_fiche_id = item.statut_fiche_id;
    newItem.statut_fiche_name = item.statut_fiche_name;

    newItem.utilisateur = item.utilisateur;
    newItem.utilisateur_id = item.utilisateur_id;
    newItem.service = item.service;
    newItem.motif_rejet = item.motif_rejet;

    newItem.type_donnee_collectee = item.type_donnee_collectee;
    newItem.FS_PF_FG_Detail = item.FS_PF_FG_Detail;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPffg


      //genere des lignes de donnees collectees pour PSSN
      generateDonneesCollecteesPSSNDetailsLines(items: donnee_collectee[]): FS_PSSN_Detail[] {
      if(items === null || items === undefined) return [];

      let finalResult: FS_PSSN_Detail[] = [];

      for (let i: number = 0; i < items.length; i++) {
        let item: FS_PSSN_Detail = new FS_PSSN_Detail();
        item.donnee_collectee = items[i];
        item.donnees_collectees_id = items[i].id;

        //check values on sexe masculin
        if( item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_10.trim() || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_11.trim()
          || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_12.trim() || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_13.trim()){
          item.TA_SUP_60 = 0;
        }

        if(item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_14.trim() || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_15.trim()){
          item.TA_0_17 = 0; item.TA_18_59 = 0;
        }

        //default all values to 0
        item.Sexe_H = 0; item.Sexe_F = 0; item.TA_0_17 = 0; item.TA_18_59 = 0; item.TA_SUP_60 = 0;
        item.Type_Secours_Enfance_Malheureuse = 0; item.Type_Secours_Indigent = 0; item.Type_Secours_Sinistre = 0;  item.Type_Secours_Immediat = 0;
        item.Type_Filet_Social_Transfert_Monetaire_Conditionnel = 0; item.Type_Filet_Social_Transfert_Monetaire_Inconditionnel = 0;
        item.Type_Appui_Formation = 0;  item.Type_Appui_Installation = 0;
        item.Type_Appui_Nutritionnel = 0; item.Type_Appui_Materiel = 0; item.Type_Appui_Ludique = 0;  item.Type_Appui_Medical = 0;
        item.Handicap = 0;

        finalResult.push(item)
      }
        return finalResult;
      }//end generateDonneesCollecteesPSSNDetailsLines


      //genere des lignes de donnees collectees pour PSSN
      generateExistingPSSNDetailsLines(items: FS_PSSN_Detail[]): FS_PSSN_Detail[] {
      if(items === null || items === undefined) return [];

            for (let i: number = 0; i < items.length; i++) {
              let item: FS_PSSN_Detail = items[i]; // new FS_PSSN_Detail();

              //check values on sexe masculin
              if( item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_10.trim() || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_11.trim()
                || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_12.trim() || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_13.trim()){
                item.TA_SUP_60 = 0;
              }
              if(item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_14.trim() || item.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_15.trim()){
                item.TA_0_17 = 0; item.TA_18_59 = 0;
              }

              //default all values to 0, if null
              if(item.Sexe_H===null) item.Sexe_H = 0;
              if(item.Sexe_F===null) item.Sexe_F = 0;
              if(item.TA_0_17===null) item.TA_0_17 = 0;
              if(item.TA_18_59===null) item.TA_18_59 = 0;
              if(item.TA_SUP_60===null) item.TA_SUP_60 = 0;
              if(item.Type_Secours_Enfance_Malheureuse===null) item.Type_Secours_Enfance_Malheureuse = 0;
              if(item.Type_Secours_Indigent===null) item.Type_Secours_Indigent = 0;
              if(item.Type_Secours_Sinistre===null) item.Type_Secours_Sinistre = 0;
              if(item.Type_Secours_Immediat===null) item.Type_Secours_Immediat = 0;
              if(item.Type_Filet_Social_Transfert_Monetaire_Conditionnel===null) item.Type_Filet_Social_Transfert_Monetaire_Conditionnel = 0;
              if(item.Type_Filet_Social_Transfert_Monetaire_Inconditionnel===null) item.Type_Filet_Social_Transfert_Monetaire_Inconditionnel = 0;
              if(item.Type_Appui_Formation===null) item.Type_Appui_Formation = 0;
              if(item.Type_Appui_Installation===null) item.Type_Appui_Installation = 0;
              if(item.Type_Appui_Nutritionnel===null) item.Type_Appui_Nutritionnel = 0;
              if(item.Type_Appui_Materiel===null) item.Type_Appui_Materiel = 0;
              if(item.Type_Appui_Ludique===null) item.Type_Appui_Ludique = 0;
              if(item.Type_Appui_Medical===null) item.Type_Appui_Medical = 0;
              if(item.Handicap===null) item.Handicap = 0;

              items[i] = item;
            }
        return items;
    }//end generateExistingPSSNDetailsLines




      //genere des lignes de donnees collectees pour fiche PE
      generateDonneesCollecteesPEDetailsLines(items: donnee_collectee[]): FS_PE_Detail[] {
        if(items === null || items === undefined) return [];

        let finalResult: FS_PE_Detail[] = [];

        for (let i: number = 0; i < items.length; i++) {
          let item: FS_PE_Detail = new FS_PE_Detail();
          item.donnee_collectee = items[i];
          item.donnees_collectees_id = items[i].id;

          //default all values to 0
          item.Sexe_H = 0; item.Sexe_F = 0; item.TA_0_4 = 0; item.TA_5_9 = 0; item.TA_10_14 = 0; item.TA_15_17 = 0;
          item.TA_15_24 = 0; item.Handicap = 0;

          finalResult.push(item)
        }
        return finalResult;
      }//end generateDonneesCollecteesPEDetailsLines

      //genere des lignes de donnees collectees pour fiche PE
      generateExistingPEDetailsLines(items: FS_PE_Detail[]): FS_PE_Detail[] {
        if(items === null || items === undefined) return [];

        for (let i: number = 0; i < items.length; i++) {
          let item: FS_PE_Detail = items[i];

          //default all values to 0
          if(item.Sexe_H === null) item.Sexe_H = 0;
          if(item.Sexe_F === null) item.Sexe_F = 0;
          if(item.TA_0_4 === null) item.TA_0_4 = 0;
          if(item.TA_5_9 === null) item.TA_5_9 = 0;
          if(item.TA_10_14 === null) item.TA_10_14 = 0;
          if(item.TA_15_17 === null) item.TA_15_17 = 0;
          if(item.TA_15_24 === null) item.TA_15_24 = 0;
          if(item.Handicap === null) item.Handicap = 0;

          items[i] = item;
        }
        return items;
      }//end generateExistingPEDetailsLines



      //genere des lignes de donnees collectees pour fiche PFFG
      generateDonneesCollecteesPFFGDetailsLines(items: donnee_collectee[]): FS_PF_FG_Detail[] {
          if(items === null || items === undefined) return [];

          let finalResult: FS_PF_FG_Detail[] = [];

          for (let i: number = 0; i < items.length; i++) {
            let item: FS_PF_FG_Detail = new FS_PF_FG_Detail();
            item.donnee_collectee = items[i];
            item.donnees_collectees_id = items[i].id;

            //check values on sexe masculin
            if(item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROSSESSES_PRECOCES_CHARGE.trim() || item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROSSESSES_PRECOCES_ENREGISTREES.trim() ){
                  item.Sexe_H = 0;
            }

            //check values on cape and groupements feminins
            if(item.donnee_collectee.designation.trim() === ConstantsService.LABEL_CAPE_ENREGISTRES.trim() || item.donnee_collectee.designation.trim() === ConstantsService.LABEL_CAPE_SUIVIS.trim() ||
              item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROUPEMENTS_FEMININS_APPUYES.trim() || item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROUPEMENTS_FEMININS_ENREGISTRES.trim() ){
              item.Sexe_H = 0; item.Sexe_F = 0; item.TA_0_4 = 0; item.TA_5_9 = 0; item.TA_10_14 = 0; item.TA_15_17 = 0;
              item.TA_0_17 = 0; item.TA_18_59 = 0; item.TA_SUP_60 = 0;
            }


            //default all values to 0
            item.Sexe_H = 0; item.Sexe_F = 0; item.TA_0_4 = 0; item.TA_5_9 = 0; item.TA_10_14 = 0; item.TA_15_17 = 0;
            item.TA_0_17 = 0; item.TA_18_59 = 0; item.TA_SUP_60 = 0; item.Handicap = 0; item.Total = 0;

            finalResult.push(item)
          }
          return finalResult;
      }//end generateDonneesCollecteesPFFGDetailsLines


      //genere des lignes de donnees collectees pour fiche PFFG
      generateExistingPFFGDetailsLines(items: FS_PF_FG_Detail[]): FS_PF_FG_Detail[] {
        if(items === null || items === undefined) return [];

        for (let i: number = 0; i < items.length; i++) {
          let item: FS_PF_FG_Detail = items[i];

          //check values on sexe masculin
          if(item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROSSESSES_PRECOCES_CHARGE.trim() || item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROSSESSES_PRECOCES_ENREGISTREES.trim() ){
            item.Sexe_H = 0;
          }

          //check values on cape and groupements feminins
          if(item.donnee_collectee.designation.trim() === ConstantsService.LABEL_CAPE_ENREGISTRES.trim() || item.donnee_collectee.designation.trim() === ConstantsService.LABEL_CAPE_SUIVIS.trim() ||
            item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROUPEMENTS_FEMININS_APPUYES.trim() || item.donnee_collectee.designation.trim() === ConstantsService.LABEL_GROUPEMENTS_FEMININS_ENREGISTRES.trim() ){
            item.Sexe_H = 0; item.Sexe_F = 0; item.TA_0_4 = 0; item.TA_5_9 = 0; item.TA_10_14 = 0; item.TA_15_17 = 0;
            item.TA_0_17 = 0; item.TA_18_59 = 0; item.TA_SUP_60 = 0;
          }

          //default all values to 0
          if(item.Sexe_H === null) item.Sexe_H = 0;
          if(item.Sexe_F === null) item.Sexe_F = 0;
          if(item.TA_0_4 === null) item.TA_0_4 = 0;
          if(item.TA_5_9 === null) item.TA_5_9 = 0;
          if(item.TA_10_14 === null) item.TA_10_14 = 0;
          if(item.TA_15_17 === null) item.TA_15_17 = 0;
          if(item.TA_0_17 === null) item.TA_0_17 = 0;
          if(item.TA_18_59 === null) item.TA_18_59 = 0;
          if(item.TA_SUP_60 === null) item.TA_SUP_60 = 0;
          if(item.Handicap === null) item.Handicap = 0;
          if(item.Total === null) item.Total = 0;

            items[i] = item;
        }
        return items;
      }//end generateExistingPFFGDetailsLines




  //format a date
     formatDate(_date: Date, format: string): string
    {
        let date: Date = new Date(_date);
        if (!format)
            format="MM/dd/yyyy";

        let month: number = date.getMonth() + 1;
        let year: number = date.getFullYear();

        format = format.replace("MM", this.padL(month.toString(), 2,"0"));

        if (format.indexOf("yyyy") > -1)
            format = format.replace("yyyy",year.toString());
        else if (format.indexOf("yy") > -1)
            format = format.replace("yy", year.toString().substr(2,2));

        format = format.replace("dd", this.padL(date.getDate().toString(), 2,"0"));

        var hours = date.getHours();
        if (format.indexOf("t") > -1)
        {
            if (hours > 11)
                format = format.replace("t","pm")
            else
                format = format.replace("t","am")
        }
        if (format.indexOf("HH") > -1)
            format = format.replace("HH",this.padL(hours.toString(), 2,"0"));
        if (format.indexOf("hh") > -1) {
            if (hours > 12) hours - 12;
            if (hours == 0) hours = 12;
            format = format.replace("hh",this.padL(hours.toString(), 2,"0"));
        }
        if (format.indexOf("mm") > -1)
            format = format.replace("mm",this.padL(date.getMinutes().toString(), 2,"0"));
        if (format.indexOf("ss") > -1)
            format = format.replace("ss",this.padL(date.getSeconds().toString(), 2,"0"));
        return format;
    }//end formatDate

    //padR
     padR(str: string, width: number, pad: string): string
    {
        if (!width || width<1)
            return str;

        if (!pad) pad=" ";
        var length = width - str.length
        if (length < 1) str.substr(0,width);

        return (str + this.repeat(pad,length)).substr(0,width);
    }//end padR

    //padL
     padL(str: string, width: number, pad: string)
    {
        if (!width ||width<1)
            return str;

        if (!pad) pad=" ";
        var length = width - str.length
        if (length < 1) return str.substr(0,width);

        return (this.repeat(pad,length) + str).substr(0,width);
    }//end padL

    //repeat
     repeat(chr: string, count: number): string
    {
        var str = "";
        for(var x=0;x<count;x++) {str += chr};
        return str;
    }//end repeat

    //get current month
    getCurrentMonth(): string {
      let monthName = this.monthList[new Date().getMonth()] ;
      return monthName;
    }//end getCurrentMonth


  //is disabled on pffg screen
  isDisabledOnPffg(lib: string): boolean {
      let labelDisabled: string[] = ConstantsService.LABEL_INDICATEURS_PFFG_ALL_DISABLED ;
      let searchLabel: string[]  = labelDisabled.filter(x => x === lib);
      if(searchLabel.length == 0) return false;
      return true;
  }//end isDisabledOnPffg

  //is grossesse disabled on pffg screen
  isGrossesseDisabledOnPffg(lib: string): boolean {
    let labelDisabled: string[] = ConstantsService.LABEL_INDICATEURS_GROSSESSES_DISABLED ;
    let searchLabel: string[]  = labelDisabled.filter(x => x === lib);
    if(searchLabel.length == 0) return false;
    return true;
  }//end isGrossesseDisabledOnPffg

  //is insertion socio professionnelle disabled on pssn screen
  isInsertionSocioProDisabledOnPssn(lib: string): boolean {
    let labelDisabled: string[] = ConstantsService.LABEL_INDICATEURS_SOCIO_PRO_DISABLED ;
    let searchLabel: string[]  = labelDisabled.filter(x => x === lib);
    if(searchLabel.length == 0) return false;
    return true;
  }//end isInsertionSocioProDisabledOnPssn

  //is naissance multiple disabled on pssn screen
  isNaissanceMultipleDisabledOnPssn(lib: string): boolean {
    let labelDisabled: string[] = ConstantsService.LABEL_INDICATEURS_NAISSANCES_MULTIPLES_DISABLED ;
    let searchLabel: string[]  = labelDisabled.filter(x => x === lib);
    if(searchLabel.length == 0) return false;
    return true;
  }//end isNaissanceMultipleDisabledOnPssn

  //is pta disabled on pssn screen
  isAppuiPtaDisabledOnPssn(lib: string): boolean {
    let labelDisabled: string[] = ConstantsService.LABEL_INDICATEURS_APPUI_PTA_DISABLED ;
    let searchLabel: string[]  = labelDisabled.filter(x => x === lib);
    if(searchLabel.length == 0) return false;
    return true;
  }//end isAppuiPtaDisabledOnPssn



  //recreate fiche fspssn details
  public recreateFicheFsPssnDetails(item: any): FS_PSSN_Detail {
    let newItem: FS_PSSN_Detail = new FS_PSSN_Detail();

    newItem.id = item.id;
    newItem.fs_pssn_id = item.fs_pssn_id;
    newItem.donnees_collectees_id = item.donnees_collectees_id;
    newItem.donnee_collectee = item.donnee_collectee;
    newItem.Sexe_H = item.Sexe_H;
    newItem.Sexe_F = item.Sexe_F;

    newItem.Type_Secours_Enfance_Malheureuse = item.Type_Secours_Enfance_Malheureuse;
    newItem.Type_Secours_Indigent = item.Type_Secours_Indigent;
    newItem.Type_Secours_Sinistre = item.Type_Secours_Sinistre;
    newItem.Type_Secours_Immediat = item.Type_Secours_Immediat;

    newItem.Type_Filet_Social_Transfert_Monetaire_Conditionnel = item.Type_Filet_Social_Transfert_Monetaire_Conditionnel;
    newItem.Type_Filet_Social_Transfert_Monetaire_Inconditionnel = item.Type_Filet_Social_Transfert_Monetaire_Inconditionnel;

    newItem.Type_Appui_Formation = item.Type_Appui_Formation;
    newItem.Type_Appui_Installation = item.Type_Appui_Installation;

    newItem.Type_Appui_Nutritionnel = item.Type_Appui_Nutritionnel;
    newItem.Type_Appui_Materiel = item.Type_Appui_Materiel;
    newItem.Type_Appui_Ludique = item.Type_Appui_Ludique;
    newItem.Type_Appui_Medical = item.Type_Appui_Medical;

    newItem.TA_0_17 = item.TA_0_17;
    newItem.TA_18_59 = item.TA_18_59;
    newItem.TA_SUP_60 = item.TA_SUP_60;

    newItem.Total = item.Total;
    newItem.Handicap = item.Handicap;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPssnDetails

  //recreate fiche fspe details
  public recreateFicheFsPeDetails(item: any): FS_PE_Detail {
    let newItem: FS_PE_Detail = new FS_PE_Detail();

    newItem.id = item.id;
    newItem.fs_pe_id = item.fs_pe_id;
    newItem.donnees_collectees_id = item.donnees_collectees_id;
    newItem.donnee_collectee = item.donnee_collectee;
    newItem.Sexe_H = item.Sexe_H;
    newItem.Sexe_F = item.Sexe_F;

    newItem.TA_0_4 = item.TA_0_4;
    newItem.TA_5_9 = item.TA_5_9;
    newItem.TA_10_14 = item.TA_10_14;
    newItem.TA_15_17 = item.TA_15_17;
    newItem.TA_15_24 = item.TA_15_24;

    newItem.Total = item.Total;
    newItem.Handicap = item.Handicap;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPeDetails


  //recreate fiche fspffg details
  public recreateFicheFsPffgDetails(item: any): FS_PF_FG_Detail {
    let newItem: FS_PF_FG_Detail = new FS_PF_FG_Detail();

    newItem.id = item.id;
    newItem.fs_pf_fg_id = item.fs_pf_fg_id;
    newItem.donnees_collectees_id = item.donnees_collectees_id;
    newItem.donnee_collectee = item.donnee_collectee;
    newItem.Sexe_H = item.Sexe_H;
    newItem.Sexe_F = item.Sexe_F;

    newItem.TA_0_17 = item.TA_0_17;
    newItem.TA_18_59 = item.TA_18_59;
    newItem.TA_SUP_60 = item.TA_SUP_60;

    newItem.TA_0_4 = item.TA_0_4;
    newItem.TA_5_9 = item.TA_5_9;
    newItem.TA_10_14 = item.TA_10_14;
    newItem.TA_15_17 = item.TA_15_17;

    newItem.Total = item.Total;
    newItem.Handicap = item.Handicap;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPffgDetails

}
