

  export const environment = {
    production: true,

	//prod params
    apiUrl: 'https://api-base-des-experts.mdscbenin.org/public/api/', 
   //apiUrl: 'https://testapimdcs.iwajutech.com/public/api/', 
	
	
	//dev params 
	//apiUrl: 'http://localhost:8000/api/',  //development
	
	resourceUrl: 'http://www.mdscbenin.org/serviceexpplus/storage/app/', //production
	
	
    //resourceUrl: 'http://localhost:8001/storage/app',  //development

  };


// ng build --prod --aot --vc --cc --dop --buildOptimizer
// ng build --prod --aot --build-optimizer
// ng  build --aot --prod --build-optimizer --output-hashing all


