import {ItDatePickerComponent} from "../../components/utils/it-datepicker/it.datepicker.component";
import {NgModule} from "@angular/core";
import {PublicLayoutRoutes} from "../../layouts/public-layout/public-layout.routing";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {CalendarModule} from "primeng/calendar";
import {TableModule} from "primeng/table";
import {DialogModule} from "primeng/dialog";
import {
  AccordionModule,
  ButtonModule,
  ChartModule,
  ConfirmDialogModule,
  FileUploadModule, GrowlModule, InputTextModule,
  MessagesModule,
  PaginatorModule
} from "primeng/primeng";
import {CarouselModule} from "ngx-owl-carousel-o";
import {ItBooleanPipe} from "../pipes/it-boolean/it-boolean.pipe";
import {FormatMoneyPipe} from "../pipes/format-money/format-money.pipe";
import {ItPaginatorPipe} from "../pipes/it-paginator/it-paginator.pipe";
import {LimitToPipe} from "../pipes/limit-to/limit-to.pipe";
import {FormatToMonthPipe} from "../pipes/format-to-month/format-to-month.pipe";
import {FormatStatusPipe} from "../pipes/format-status/format-status.pipe";
import {PublicHeaderComponent} from "../../components/utils/public-header/public-header.component";
import {NumberOnlyDirective} from "../directives/number.directive";
import {HasRightDirective} from "../directives/hasright.directive";
import { FormatLanguePipe } from '../pipes/format-langue/format-langue.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CarouselModule,
    InputTextModule, ButtonModule, CalendarModule, PaginatorModule, ChartModule, MessagesModule, DialogModule, ConfirmDialogModule,
    GrowlModule, FileUploadModule, TableModule, AccordionModule,
  ],
  declarations: [
    ItDatePickerComponent, PublicHeaderComponent,
    //pipes
    ItPaginatorPipe, FormatMoneyPipe, LimitToPipe, ItBooleanPipe, FormatToMonthPipe, FormatStatusPipe,
    FormatLanguePipe,
    //directives
    NumberOnlyDirective, HasRightDirective,
  ],
  exports: [
    ItDatePickerComponent, PublicHeaderComponent,
    //pipes
    ItPaginatorPipe, FormatMoneyPipe, LimitToPipe, ItBooleanPipe, FormatToMonthPipe, FormatStatusPipe,
    FormatLanguePipe,
    //directives
    NumberOnlyDirective, HasRightDirective,
  ]
})

export class SharedModule {}
