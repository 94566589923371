import {Injectable} from "@angular/core";


@Injectable()
export class ConstantsService {

    static instance: ConstantsService;

    constructor() {
        ConstantsService.instance = this;
    }


    //liste de mois
  MONTH_LIST = [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aôut","Septembre","Octobre","Novembre","Décembre" ];

    //LISTE DES THEMATIQUES
  DOMAINE_LISTE = [
    //{ id: 1, designation: ""  }
  ];


  THEMATIQUE_LISTE = [
    {

    }
  ];



  //libelles et valeurs utilisées sur les formulaires dedition et decreation de fiches

  //fiche protection sociale et solidarite nationale
  static REFERENCE_DOMAINE_PSSN: number = 5;
  static LABEL_TYPE_SECOURS: string = "type_secours"; static DESIGNATION_TYPE_SECOURS: string = "Secours nationaux";
  static LABEL_FILET_SOCIAL: string = "filet_social"; static DESIGNATION_FILET_SOCIAL: string = "Filets sociaux";
  static LABEL_CAS_SOCIAUX: string = "cas_sociaux"; static DESIGNATION_CAS_SOCIAUX: string = "Insertion socio-professionnelle des jeunes cas sociaux";
  static LABEL_APPUI_GENERAL: string = "appui_general"; static DESIGNATION_APPUI_GENERAL: string = "Personne de troisième âge";

  //fiche protection de l'enfant
  static REFERENCE_DOMAINE_PE: number = 3;
  //static LABEL_TYPE_SECOURS: string = "type_secours"; static DESIGNATION_TYPE_SECOURS: string = "Secours nationaux";

  //fiche protection de la famille, de la femme et du genre
  static REFERENCE_DOMAINE_PFFG: number = 4;
  static LABEL_CAPE_FAMILLE_ACCUEIL: string = "cape_famille_accueil";    static LABEL_NOT_CAPE_FAMILLE_ACCUEIL: string = "not_cape_famille_accueil";
  static DESIGNATION_ACCOMPAGNEMENT_CAPE: string = "Accompagnement CAPE";   static DESIGNATION_FAMILLES: string = "Familles hôtes / familles d'accueil";


  //lib disabled
  static LABEL_CAPE_ENREGISTRES: string = "Nombre de CAPE enregistrés";
  static LABEL_CAPE_SUIVIS: string = "Nombre de CAPE suivis";
  static LABEL_GROUPEMENTS_FEMININS_APPUYES: string = "Nombre de groupements féminins/mixte appuyés";
  static LABEL_GROUPEMENTS_FEMININS_ENREGISTRES: string = "Nombre de groupements féminins/mixte enregistrés";
  static LABEL_INDICATEURS_PFFG_ALL_DISABLED: string [] = [ConstantsService.LABEL_CAPE_ENREGISTRES, ConstantsService.LABEL_CAPE_SUIVIS, ConstantsService.LABEL_GROUPEMENTS_FEMININS_APPUYES, ConstantsService.LABEL_GROUPEMENTS_FEMININS_ENREGISTRES];

  static LABEL_GROSSESSES_PRECOCES_CHARGE: string = "Nombre de grossesses précoces pris en charge";
  static LABEL_GROSSESSES_PRECOCES_ENREGISTREES: string = "Nombre total de grossesses précoces enregistrées dans les structures de prise en charge";
  static LABEL_INDICATEURS_GROSSESSES_DISABLED: string [] = [ ConstantsService.LABEL_GROSSESSES_PRECOCES_CHARGE, ConstantsService.LABEL_GROSSESSES_PRECOCES_ENREGISTREES ];



  //ndicateurs pssn avec des niveaux de desagregation supplementaire
  static DESIGNATION_INDICATEUR_PSSN_1: string = "Nombre total de demandes de secours nationaux satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_2: string = "Nombre total de demandes de secours nationaux reçues par les CPS, SSS et autres ONG partenaires";


  static DESIGNATION_INDICATEUR_PSSN_3: string = "Nombre de ménages bénéficiaires de filets sociaux";
  static DESIGNATION_INDICATEUR_PSSN_4: string = "Nombre de ménage pauvres identifiés";
  static DESIGNATION_INDICATEUR_PSSN_5: string = "Somme des montants octroyés pour les filets sociaux";

  static DESIGNATION_INDICATEUR_PSSN_6: string = "Nombre de jeunes cas sociaux appuyés (appuiinstallation, formation dans les cours éducatifs)";
  static DESIGNATION_INDICATEUR_PSSN_7: string = "Nombre total de jeunes cas sociaux identifiés par les CPS, ONG partenaire";

  static DESIGNATION_INDICATEUR_PSSN_8: string = "Nombre total de demandes d'appui des PTA satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_9: string = "Nombre total d'appuis sollicités par les PTA auprès des CPS, SSS et autres structures partenaires,";


  static DESIGNATION_INDICATEUR_PSSN_10: string = "Nombre de jeunes cas sociaux appuyés (appuiinstallation, formation dans les cours éducatifs)";
  static DESIGNATION_INDICATEUR_PSSN_11: string = "Nombre total de jeunes cas sociaux identifiés par les CPS, ONG partenaire";
  static LABEL_INDICATEURS_SOCIO_PRO_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_10,  ConstantsService.DESIGNATION_INDICATEUR_PSSN_11 ];

  static DESIGNATION_INDICATEUR_PSSN_12: string = "Nombre total de demandes de naissances multiples appuyés";
  static DESIGNATION_INDICATEUR_PSSN_13: string = "Nombre total de demandes de naissances multiples enregistrées par les CPS, SSS et autres ONG";
  static LABEL_INDICATEURS_NAISSANCES_MULTIPLES_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_12, ConstantsService.DESIGNATION_INDICATEUR_PSSN_13  ];

  static DESIGNATION_INDICATEUR_PSSN_14: string = "Nombre total de demandes d'appui des PTA satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_15: string = "Nombre total d'appuis sollicités par les PTA auprès des CPS, SSS et autres structures partenaires,";
  static LABEL_INDICATEURS_APPUI_PTA_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_14, ConstantsService.DESIGNATION_INDICATEUR_PSSN_15  ];


  static STATUT_FICHE_ANNULATION: string = "ANNULATION";




}
