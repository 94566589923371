import {Component, OnInit, TemplateRef, Inject, HostListener} from '@angular/core';
import { DOCUMENT } from "@angular/platform-browser";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {ReportService} from "../../../../shared/services/report/report.service";
import {structure} from "../../../../shared/model/structure";
import {ParamService} from "../../../../shared/services/param/param.service";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {utilisateur} from "../../../../shared/model/utilisateur";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as FileSaver from 'file-saver';
import {localisation} from "../../../../shared/model/localisation";
import { ActivatedRoute } from '@angular/router';
import {ConfirmationService} from "primeng/api";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {GenericModalComponent} from "../../../utils/generic-modal/generic-modal.component";
import { DomSanitizer } from '@angular/platform-browser';

import { EmbedVideoService } from 'ngx-embed-video';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs/Subscription';

@Component({
  selector: 'app-accueil-competence',
  templateUrl: './accueil_competence.component.html',
  styleUrls: ['./accueil_competence.component.css']
})
export class AccueilCompetenceComponent implements OnInit {

  constructor (private router: Router, private confirmationService: ConfirmationService, private modalService: NgbModal,
              private toastr: ToastrService, private _sanitizer: DomSanitizer,
               private embedService: EmbedVideoService, @Inject(DOCUMENT) private document: Document
  )
  {
    this.videoURL =  "https://youtu.be/1TFZDpbHOLQ";
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
    this.iframe_html = this.embedService.embed(this.videoURL,
      {
          query: { portrait: 0, color: '333' },
          attr: { width: 800, height: 400 }
      });

  }
  @HostListener("window:scroll", [])
  windowScrolled: boolean;
  closeResult: string;
  contentFirst : TemplateRef<any>;
  contentSecond : TemplateRef<any>;

  videoURL: string = "";
  iframe_html: any;
  safeURL: any ;
  
  images: any[] = [ 
	{ image: "expert_rep2.png", text: "La base de données des experts de la société civile", intro: "MdSC Expertise Plus"},
	{ image: "expert_rep1.png", text: "Faites connaitre votre expertise", intro: "MdSC Expertise Plus"},
	{ image: "expert_rep3.png", text: "Valorisez votre expérience", intro: "MdSC Expertise Plus"},
	];


    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        } 
       else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }



  ngOnInit() {
	  
	 
  }//end oninit

  ngOnDestroy() {
  }

  //soumettre fiche
  soumettre(): void {
    //serie de question

    const modalFirstScreen = this.modalService.open(GenericModalComponent);
    modalFirstScreen.componentInstance.title = 'MDSC Expertise Plus';
    modalFirstScreen.componentInstance.status = true;
    modalFirstScreen.componentInstance.message = 'Avez-vous au moins assumé une mission de Consultant et/ou d’Expert une fois? ';


    //check the response
    modalFirstScreen.componentInstance.aClickedEvent.subscribe(
      (data: any) => {

        if(data === "true"){

          //good
          const modalSecondScreen = this.modalService.open(GenericModalComponent);
          modalSecondScreen.componentInstance.title = 'MDSC Expertise Plus';
          modalSecondScreen.componentInstance.message = 'Si oui, avez vous des documents qui l\'attestent? ';
          modalSecondScreen.componentInstance.status = true;
          modalSecondScreen.componentInstance.aClickedEvent.subscribe(
            (data: any) => {

              if(data === "true") {
                this.router.navigate(["/register"]);
              }else{

                this.showErrorMessage("Désolé, vous n'êtes pas éligible");

                // let error: string = "Désolé, vous n'êtes pas éligible.";
                // this.toastr.error(error, "MDSC Expertise Plus", {positionClass: 'toast-top-center'});
                return;
              }
            });

        }else{
          // let error: string = "Désolé, vous n'êtes pas éligible.";
          // this.toastr.error(error, "MDSC Expertise Plus", {positionClass: 'toast-top-center'});


          this.showErrorMessage("Désolé, vous n'êtes pas éligible");

          return;
        }
    });
  }//end soumettre


  //show error message
  showErrorMessage(text: string): void {
    const modalFirstScreen = this.modalService.open(GenericModalComponent);
    modalFirstScreen.componentInstance.title = 'MDSC Expertise Plus';
    modalFirstScreen.componentInstance.message = text;
    modalFirstScreen.componentInstance.status = false;

  }//end showErrorMessage


  //rechercher fiche
  rechercher(): void {
    this.router.navigate(["/rechercher_competence"]);
  }//end rechercher

  //connect user
  connect(): void {
    this.router.navigate(["/login"]);
  }//end connect

  //consult list of users
  consult(): void {
    this.router.navigate(["/dashboard_competences"]);
  }//end consult


  //go admin space
  goAdminSpace(): void {
    this.router.navigate(["/adminlogin"]);
  }//end goAdminSpace

	//scroll to top
	scrollToTop(): void {
		(function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
	}//end scrollToTop








}
